import $ from 'jquery'
import Events from './events'
import OTGDate from './otg-date'
import OTG_API from './otg-api-get'

  var eventCache = {}
  function Event( data ){
    if( eventCache[ data.id ] ){
      $.extend( this, eventCache[ data.id ] )
    } else {
      $.extend( this, data )
      this.setupAtts()
      this.detailsFetched = false
      eventCache[this.id] = this
    }
  }
  Event.prototype.fetchDetails = function(){
    if( this.detailsFetched ){
      return $.Deferred().resolve().promise()
    } else {
      this.detailsFetched = true
      var def = $.Deferred()
      OTG_API.get( '/events/' + this.id )
        .done( ( response )=>{
          var l = this.locationFetched ? { location: this.location } : l = {}
          $.extend( this, response.data.event, l )
          this.setupAtts().done( ()=>{
            eventCache[this.id] = this
            def.resolve( this )
          } )
        } )
        .fail( function(){ def.reject() })
      return def.promise()
    }
  }
  Event.prototype.fetchLocation = function(){
    if( this.locationFetched ){
      return this.locationFetched
    }else{
      var def = $.Deferred()
      this.locationFetched = def.promise()
      OTG_API.getLocation( this.locationId )
        .done( function( location ){
          this.location = location
          def.resolve()
        }.bind(this))
        .fail( function(){
          def.reject()
        })
      return def.promise()
    }
  }
  Event.prototype.setupAtts = function(){
    var def = $.Deferred()
    if( this.services ){
      var promises = []
      this.vendors = []
      this.services.forEach( ( service )=>{
        var p = OTG_API.getVendor( service.vendor.id )
        promises.push( p )
        p.done( vendor => {
          service.vendor = vendor
          this.vendors.push( vendor )
        } )
        if( typeof this.assignedVendors == 'undefined' ){
          this.assignedVendors = this.services.length
        }
      } )
      $.when.apply( null, promises )
        .done( def.resolve )
    } else { def.resolve() }
    this.startTime = new OTGDate( this.startTime )
    this.endTime = new OTGDate( this.endTime )
    this.timeSpan = `${this.startTime.toFormat('h:mm')} ${this.startTime.toFormat('a')} - ${this.endTime.toFormat('h:mm')} ${this.endTime.toFormat('a')}`
    this.date = [
      this.startTime.getFullYear(),
      this.startTime.getMonth(),
      this.startTime.getDate()
    ].join('-')
    this.timeSlug = this.date + '-' + this.timeSpan
    if( ! this.location && this.locationId ){
      this.location = new OTG_API.Location( { id: this.locationId } )
    }
    if( ! (this.location instanceof OTG_API.Location) ){
      this.location = new OTG_API.Location( this.location )
    }
    return def.promise()
  }

  OTG_API.getEvent = function( id ){
    var def = $.Deferred()
    var e = new Event( { id: id } )
    e.fetchDetails().done( function(){
      def.resolve( e )
    })
    return def.promise()
  }


  var getEventsHelpers = {
    setDefaults: function( args ){
      if( args.dateFrom === undefined ){
        args.dateFrom = new Date().setHours(0,0,0,0)
      }
      if( args.dateTo === undefined ){
        var now = new Date()
        var later = new Date()
        later.setDate( now.getDate() + 14 )
        later.setHours( 0,0,0,0 )
        args.dateTo = later.getTime()
      }
      return args
    },
    locationIds : {/* id: jQuery Deferred */},
    locationQueueTimeoutID: false
  }
  OTG_API.getEvents = function( args ){
    // Returns a promise that resolves with an Events collection
    var def = $.Deferred()
    var h = getEventsHelpers
    args = h.setDefaults( args )

    if( args.locationId ){
      h.locationIds[args.locationId] = def
      if( h.locationQueueTimeoutID ){ clearTimeout( h.locationQueueTimeoutID ) }
      h.locationQueueTimeoutID = setTimeout(
        function(){
          var IDs = Object.keys( h.locationIds )
          var defs = h.locationIds
          h.locationIds = {}
          // if more than 10 locations, just fetch all events
          args.locationId = IDs.length > 10 ? undefined : IDs
          var request = OTG_API.get( '/events', args )
          request
            .done( function( response ){
              IDs.forEach( function( id ){
                var events = response.data.events.filter( function( event ){ return event.locationId == id } )
                events = events.map( function( data ){ return new Event( data ) } )
                events = new Events( ...events )
                defs[id].resolve( events )
              })
            })
            .fail( function(){
              IDs.forEach( function( id ){
                defs[id].reject()
              })
            })
        },
        150
      )
    } else {
      OTG_API.get( '/events', args ).done(function( response ){
        var events = response.data.events.map( function( data ){ return new Event( data ) })
        events = new Events( ...events )
        def.resolve( events )
      })
    }

    return def.promise()
  }

export default OTG_API
