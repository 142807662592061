import $ from 'jquery'
import React from 'react'
import VendorCount from '../../vendor-count'
import './event.scss'

export default class Event extends React.Component {
  render(){
    let event = this.props.event
    let eventLink = event.location.permalink + '/' + event.startTime.getDateTime()
    return (
      <div className={"events-list__item" + (event.isFeatured ? ' is-special' : '')}>
        { event.isFeatured &&
          <div className="events-list__badge">
            <img
              className="events-list__icon--star"
              alt="Relief Effort Location"
              src={ THEME_DIR_URI + '/img/icon-star.svg' }
            />
            Special Event
          </div>
        }
        <div className="events-list__flex">
          <div className="events-list__content--info">
            <div className="events-list__dates">
              { event.location &&
                <a className="events-list__link" href={ eventLink }>
                  <span className="events-list__time">
                  {event.timeSpan}
                  { event.location.locationType == 'Relief Effort' &&
                    <img
                      className="events-list__icon"
                      alt="Relief Effort Location"
                      src={ THEME_DIR_URI + '/images/disaster-relief-icon.png' }
                    />
                  }
                  </span>
                  <span className="events-list__name">
                    {event.name}
                  </span>
                </a>
              }
            </div>
          </div>
          <div className="events-list__content--location">
            <div className="events-list__location">
              { event.location &&
                <a
                className="events-list__link--map"
                target="_blank"
                href={
                  "http://maps.google.com/?q=" +
                  event.location.latitude + ',' +
                  event.location.longitude }
                >
                <i className="fa fa-map-marker"></i>
                <span>{event.location.address1}, {event.location.city}</span>
              </a>
              }
            </div>
          </div>
          <div className="events-list__content--cta">
            <a className="events-list__cta" href={ eventLink }>
              { event.isFeatured ? 'Learn More' : 'View Creators'}
            </a>
          </div>
        </div>
      </div>
    )
  }
}
