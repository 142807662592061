import React       from 'react'
import MarketsList from './markets-list'
import MarketsMap  from './markets-map'
import EventsList  from './events-list'

export default class MarketsDisplay extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			marketsInBounds: [],
			filterListByMapBounds: this.props.filterListByMapBounds !== undefined ? this.props.filterListByMapBounds : true
		};
	}
	marketClicked = ( event, market )=>{
		event.preventDefault()
		this.props.setCurrentMarket( market )
	}
	setMarketsInBounds = ( markets )=>{
		this.setState( { marketsInBounds: markets } )
	}
	render(){
		switch( this.props.display ){
			case 'map':
				var marketsToDisplay = []
				if( this.props.markets && this.state.filterListByMapBounds ){
					marketsToDisplay = this.props.markets.filter( function( market ){
						return undefined !== this.state.marketsInBounds.find( function( inBoundsMarket ){
							return market.id == inBoundsMarket.id
						} )
					}.bind(this))
				} else {
					marketsToDisplay = this.props.markets
				}
				return (
					this.props.markets.length > 0 ?
						<section className="events with-map">
								<section className="events-wrap">
									<MarketsList
										currentMarket={ this.props.currentMarket }
										marketClicked={ this.marketClicked }
										displayImage={false}
										firstLoadedMarket={this.props.firstLoadedMarket}
										markets={ marketsToDisplay }
										hideVendorList={true}
										message={this.props.message}
										noFetch={false}
										noneFoundMessage={"No events matched your search criteria, try zooming out on the map."}
									/>
								</section>
								<section className="map-wrap">
									<MarketsMap
										ref="map"
										allMarkets={this.props.allMarkets}
										markets={this.props.markets}
										location={this.props.location}
										currentMarket={this.props.currentMarket}
										setCurrentMarket={this.props.setCurrentMarket}
										setMarketsInBounds={this.setMarketsInBounds}
									/>
								</section>
						</section>
					:
						<section className="events without-map">
								<section className="events-wrap">
									<MarketsList
										currentMarket={ this.props.currentMarket }
										marketClicked={ this.marketClicked }
										displayImage={false}
										markets={this.props.markets}
										message={this.props.message}
										distanceFilterActive={this.props.distanceFilterActive}
									/>
								</section>
						</section>
				);
				break;
			case 'list':
				return <EventsList {...this.props} />
				break;
			default:
				return <MarketsList
									display="grid"
									distanceFilterActive={this.props.distanceFilterActive}
									markets={this.props.markets}
									message={this.props.message}
								/>
		}
	}
}
