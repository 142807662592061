export default function(paths, next){
    function handleInject(path) {
      var script = document.createElement("script");
      script.src = path;
      document.body.appendChild(script);
    }
    function handlePaths() {
      if( Array.isArray(paths) ){
        paths.forEach(function(path){
          handleInject(path)
        })
      }
      if( typeof paths === 'string' ){
        var path = paths
        handleInject(path)
      }
    }
    handlePaths()

    if (next)
      return next()
  }
