import $ from 'jquery'
export default function toClassName( name ){
	switch( $.type(name) ){
		case "string":
			var name = name.replace( "'", '' )
			return name.toLowerCase().replace(/\W/g, '-')
			break;
		case "array":
			name = name.map( toClassName )
			return name.join(' ')
			break
		default:
			return ""
	}
}
