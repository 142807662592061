import React from 'react'
import { css } from 'glamor'
import { space } from '../../variables'

const defaultLocationMessage = {
  	textAlign: 'center',
  	marginTop: space ,
}

export default class extends React.Component {
	state = {
			minDistance: 0,
			maxDistance: 50
		}
	componentDidMount() {
		/*
		*********************************************************
		Begin IE input range hack to ensure change event is fired
		*********************************************************
		*/
	  var fixInputAndChangeEvents = function() {
	    var currentSlider;
	    var fireChange = function(e) {
	      var changeEvent = document.createEvent('Event');
	      changeEvent.initEvent('change', true, true);

	      changeEvent.forceChange = true;
	      currentSlider.dispatchEvent(changeEvent);
	    };

	    document.addEventListener('change', function(e) {
	      var inputEvent;
	      if (!e.forceChange && e.target.getAttribute('type') === 'range') {
	        e.stopPropagation();
	        inputEvent = document.createEvent('Event');
	        inputEvent.initEvent('input', true, true);

	        e.target.dispatchEvent(inputEvent);

	        currentSlider = e.target;
	        document.removeEventListener('mouseup', fireChange);
	        document.addEventListener('mouseup', fireChange);
	      }

	    }, true); // make sure we're in the capture phase
	  };

	  var isIE = function() {
	    var userAgent = navigator.userAgent;
	    return userAgent.indexOf('MSIE') !== -1 ||
	      userAgent.indexOf('Trident') !== -1;
	  };

	  if (isIE()) {
			var input = document.getElementsByClassName('distance-filter')[0];

			input.addEventListener('input', function(e) {
				console.log('input');
			});

			input.addEventListener('change', function(e) {
				console.log('change');
			});

	    fixInputAndChangeEvents();
	  }
		/*
		*******************************************************
		End IE input range hack to ensure change event is fired
		*******************************************************
		*/
	}
	render(){
		return (
			<div className="distance-filter-wrapper">
				<div className="distance-filter-value">{this.props.distanceFilterActive ? "Showing events within " + this.props.distance + " mi" : "Showing events within any distance"}</div>
				<input
					className={this.props.distanceFilterActive ? "distance-filter" : "distance-filter disabled"}
					type="range" min={this.state.minDistance}
					max={this.state.maxDistance}
					value={this.props.distance}
					onInput={this.props.updateDistance}
					onChange={this.props.updateDistance}
					/>
				<div className={this.props.distanceFilterActive ? "distance-filter-range" : "distance-filter-range disabled"}>
					<span className="distance-filter-min">{this.state.minDistance + " mi"}</span>
					<span className="distance-filter-max">{this.state.maxDistance + " mi"}</span>
				</div>
				<div className="distance-filter-disable-wrapper">
					<label>
						<input className="distance-filter-disable" type="checkbox" checked={!this.props.distanceFilterActive} onClick={this.props.toggleDistanceFilter} />
						<span className="distance-filter-disable-label">Any Distance</span>
					</label>
				</div>
				{ this.props.location && this.props.location.default ?
					<div className={`default-location-message ${ css( defaultLocationMessage ) }`}>
						Your current location has not been shared. Showing events closest to San Francisco
					</div> : undefined
				}
			</div>
		)
	}
}
