import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import {DateTime} from 'luxon'
import debounce from 'debounce'
import Exitent from 'Exitent'
import GFormDialog from '/components/gform-dialog'
import { SCREENSMMIN } from '/js/variables/breakpoints'

export function initDialogSetup() {
  if( shouldShowDialog() ){
    window.setTimeout( setupDialog, 4000 )
  }
}

export function triggerShowDialog(clickthrough) {
  if( shouldShowDialog() ){

    showDialog( clickthrough )
    
  } else {

    location.href = clickthrough

  }
}

function setupDialog(){
  if( window.innerWidth > SCREENSMMIN ){
    new Exitent(
      {
        maxDisplays: 1,
        storageLife: 0, // days
        onExitent: showDialog
      }
    )
    const showAfterScroll = debounce( ()=>{
      if( window.scrollY > ( document.body.scrollHeight * .4 )){
        showDialog()
        window.removeEventListener( 'scroll', showAfterScroll )
      }
    }, 500 )
    window.addEventListener( 'scroll', showAfterScroll )
  } else {
    showDialog()
  }
}

function showDialog(clickthrough){
  const container = $('<div>')
  container.appendTo('body')
  ReactDOM.render(
    <GFormDialog clickthrough={ clickthrough } />,
    container[0]
  )

  const today = DateTime.local().toISODate()
  let impressions = localStorage.getItem('GFormDialogImpressions')
  localStorage.setItem( 'GFormDialogImpressions', ++impressions )
  localStorage.setItem( 'GFormDialogDate', today )

  sessionStorage.setItem('GFormDialogShown', true)

}
function shouldShowDialog(){
  const today = DateTime.local().toISODate()
  const storedDate = localStorage.getItem( 'GFormDialogDate' )
  const dialogShown = sessionStorage.getItem('GFormDialogShown');
  let impressions = localStorage.getItem('GFormDialogImpressions')
  impressions = impressions ? parseInt( impressions ) : 0

  if( today !== storedDate ){
    impressions = 0
  }
  
  if( impressions < 2 && ! dialogShown ){
    return true
  }
  return false
}
