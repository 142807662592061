import OTG_API from './otg-api'

import Events from './otg-api-events'
import OTGLocation from './otg-api-location'
import Menu from  './otg-api-menu'
import Vendor from './otg-api-vendor'

Object.assign( OTG_API,
  Events,
  OTGLocation,
  Menu,
  Vendor
)

export default OTG_API
