import React from 'react'
import './styles.scss'

export default function ClickThrough( { link, text, additional } ) {
  return (
    <a className="event-clickthrough" href={ link }>
      <div className="event-clickthrough__text"><div className="event-clichkthrough__vertical-centered">{ text }</div></div>
      { additional &&
        <div className="event-clickthrough__additional-text">
          <div className="event-clickthrough__additional-text__content">{ additional }</div>
        </div>
      }
    </a>
  )
}
