import PermalinkBase from '../../variables/siteurl'
import $ from 'jquery'
import OTG_API from './otg-api-cuisine'

  var vendorsCache = {}
  function Vendor( data ){
    $.extend( this, data )
    if( vendorsCache[data.id] ){
      $.extend( this, vendorsCache[data.id] )
    } else {
      this.setupAtts()
      this.saveToCache()
    }
  }
  Vendor.prototype.fetchCuisines = function(){
    // at this point, we are only using the primary cuisine,
    // so we set the .cuisine property to the primary cuisine name. 2017-04-21

    var def = $.Deferred()
    if( this.primaryCuisine ){
      OTG_API.getCuisine( this.primaryCuisine.id )
        .done(
          function( c ){
            if( c ){
              this.cuisine = c.name
              this.saveToCache()
            }
            def.resolve( c )
          }.bind( this )
        )
    } else {
      def.resolve()
    }
    return def.promise()
  }
  Vendor.prototype.fetchDetails = function(){
    var def = $.Deferred()
    OTG_API.get( '/vendors/' + this.id )
      .done(
        function( response ){
          $.extend( this, response.data.vendor )
          this.setupAtts()
          this.saveToCache()
          def.resolve( this )
        }.bind(this)
      )
      .fail(
        function( failure ){
          def.reject( failure )
        }
      )
    return def.promise()
  }
  Vendor.prototype.fetchSchedule = function(){
    // returns a promise that resolves when the 'schedule' attribute is set
    var def = $.Deferred()
    if( undefined == this.schedule ){
      OTG_API.getEvents( { vendorId: this.id } )
        .done( function( events ){
          this.schedule = events
          this.saveToCache()
          def.resolve()
        }.bind(this) )
        .fail( def.reject )
    } else {
      def.resolve()
    }
    return def.promise()
  }
  Vendor.prototype.saveToCache = function(){
    vendorsCache[this.id] = this
    vendorsCache[this.slug] = this
  }
  Vendor.prototype.setupAtts = function(){
    this.permalink = PermalinkBase + '/creator/' + this.slug
  }

  OTG_API.getVendor = function( id ){
    // id may be vendor slug or vendor id
    var def = $.Deferred()
    def.resolve( new Vendor( { 'id': id } ) )
    return def
  }


  // The response from this endpoint shouldn't change much, so only call it once
  // per pageload.
  var getVendorsAPICall = false
  OTG_API.getVendors = function(){
    var def = $.Deferred()

    if( getVendorsAPICall === false ){
      getVendorsAPICall = OTG_API.get( '/vendors' )
    }
    getVendorsAPICall.done(
      function( response ){
        var vendors = []
        response.data.vendors.forEach( function( data ){
          vendors.push( new Vendor( data ) )
        } )
        def.resolve( vendors )
      }
    )
    return def.promise()
  }


  export default OTG_API
