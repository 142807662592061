import $ from 'jquery'
import React, { Component } from 'react'
import { css } from 'glamor'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import close from '/images/mobile-close.svg'
import './styles.scss'

const closeButtonStyles = {
  float: 'right',
  '&:hover': {
    cursor: 'pointer',
  }
}

export default class GFormDialog extends Component {
  state = {
    open: true
  }
  contentRef = React.createRef()
  close = () => {
    const { clickthrough } = this.props;

    this.setState({ open: false })

    if ( clickthrough ) {

      location.href = clickthrough

    }
  }
  onEnter = () => {
    this.form = $('.field-popout.email-subscribe')
    this.placeholder = $('<div>')
    this.placeholder.insertAfter( this.form )
    $(this.contentRef.current).append( this.form )
  }
  onExit = () => {
    this.placeholder.replaceWith( this.form )
    this.placeholder = undefined
  }
  render(){
    const { open } = this.state
    return (
      <Dialog open={open} onEnter={this.onEnter} onExit={this.onExit} onBackdropClick={ this.close }
        classes={ { paper: css({ margin: '20px!important' }) } }
        >
        <DialogContent>
          <img className={ css( closeButtonStyles )} src={close} alt="close" onClick={ this.close } />
          <h4 className="popup-title">Keep it tasty</h4>
          <p className="popup-intro">Sign up for weekly market schedules, news and stuff we like.</p>
          <div className="gform-dialog__content popup-content" ref={ this.contentRef } />
        </DialogContent>
      </Dialog>
    )
  }
}
