import { DateTime } from 'luxon'
import Event from './event'
import React from 'react'
import Timezone from '../../../variables/timezone'

export default function Day( props ){
	var first = props.events[0]
  let startTime = DateTime.fromMillis( first.startTime.valueOf(), { zone: Timezone } )
  let today = DateTime.fromObject({}, { zone: Timezone } )


  let isToday = startTime.hasSame( today, 'day')

	return (
		<div className="events-list">
			<h5 className="events-list__title">
        { isToday && 'Today - ' }
        <span>
        { startTime.weekdayLong + ' '}
        { first.startTime.getMonth() }/{ first.startTime.getDate() }
        </span>
      </h5>
			{ props.events.map(
        event => {
  				return <Event key={ event.id } event={ event } />
  			})
      }
		</div>
	)
}
