import $ from 'jquery'
import React from 'react'
import { createRoot } from 'react-dom/client';
import Day from '../components/markets-index/events-list/day'
import {DateTime} from 'luxon'
import OTG_API from '/lib/otg-api'

let today_ts = DateTime.fromObject({}, {zone:'America/Los_Angeles'}).ts;
let today = DateTime.fromMillis(today_ts)
let sod = today.startOf('day')
let eod = sod.endOf('day')
let args = { dateFrom: sod.valueOf(), dateTo: eod.valueOf() }

export default function initUpcomingEvents(){
  const targets = document.getElementsByClassName('js-events-list')
  if( targets.length > 0 ){
    OTG_API.getEvents( args )
    .done( ( events )=>{
      let promises = events.map( event => {
        return event.fetchLocation()
      } )
      $.when( ...promises )
      .done( () => {
        const root = createRoot( targets[0] )
        root.render( <Day events={events} /> )
      } )
    })
  }
}
