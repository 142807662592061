export default function( markets ){
  var output = {
    'all': markets,
    'opennow': [],    // initialize these
    'opentoday': [],  // so we don't have to
    'closed' : []     // check if they exist in other methods
  }
  markets.forEach(
    market => {
      output[ market.status ].push( market )
    }
  )
  return output;
}
