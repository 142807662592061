let root = document.getElementById('otg-widget')
let siteURL = ''

if( root ){
   siteURL = root.getAttribute('data-site-url')
} else {
  siteURL = SITE_URL
}

export default siteURL
