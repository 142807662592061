import React from 'react'
import Day from './day'
import Clickthrough from './clickthrough'
import './styles.scss'

var tomorrow = new Date()
tomorrow.setDate( tomorrow.getDate() + 1 )
tomorrow.setHours( 0,0,0,0 )

export default class extends React.Component {
	static defaultProps = {
		events: { byDate: {} },
		date: new Date()
	}
	render(){
		var dates = this.props.events.byDate
		var disabled = this.props.date.getTime() < tomorrow.getTime()
		return (
			<section className="events-list">
				<header>
          { this.props.clickThrough &&
            <Clickthrough link={this.props.clickThrough.link} text={this.props.clickThrough.text} additional={this.props.clickThrough.additional} />
          }
					<div className="events-list__nav-buttons">
						<button className="button button-big" disabled={disabled} onClick={ this.changeDay.bind( this, -7 ) }>Previous Week</button>
						<button className="button button-big" onClick={ this.changeDay.bind( this,  7 ) }>Next Week</button>
					</div>
				</header>
				<div>
				{
					Object.keys(dates).map( function( k ){
						return <Day key={k} events={dates[k]} />
					})
				}
				</div>
			</section>
		)
	}
	changeDay( days ){
		let d = new Date( this.props.date )
		d.setDate( d.getDate() + days )
		this.props.setDate( d )
	}
}
