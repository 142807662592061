import React from 'react'
import {Component} from 'react'
import './page-header.scss'
export default class extends Component {
  getBackgroundImage(){
    let image = THEME_DIR_URI + '/images/placeholder-truck.svg'
    if( this.props.backgroundImage ){ image = this.props.backgroundImage }
    return 'url(' + image + ')'
  }
  render(){
    return (
      <header className="page-header" style={ { backgroundImage: this.getBackgroundImage() } } >
        {this.props.heading && <h1>{this.props.heading}</h1>}
        {this.props.logo && <img className="page-header__logo circle" src={ this.props.logo } /> }
      </header>
    )
  }
}
