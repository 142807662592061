import React from 'react'
import {Component} from 'react'
export default class extends Component{
  render(){
    switch ( this.props.status ) {
      case undefined:
        return <div className="message message_loading loading loading-markets">Finding nearby events&hellip;</div>
        break;
      case 'loading':
        return <div className="message message_loading loading loading-markets">Finding events&hellip;</div>
      default:
        return false
    }
  }
}
