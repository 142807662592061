import $ from 'jquery'
export default function( callback ) {
	var def = $.Deferred()
	var failure = function( positionError, b, x){
		console.log(
			'Geolocation Failed \n' +
			(! window.isSecureContext ? 'connection is insecure\n': '') +
			( positionError !== undefined ? "user denied location access\n" : '' ) +
			positionError + "\n" +
			'rejecting deffered object'
		)
		def.reject()
	}
	var success = function( position ){
		var pos = {
			lat: position.coords.latitude,
			lng: position.coords.longitude,
			default: false
		}
		console.log(
			'LOCATION DEBUG: User location found using navigator.geolocation\n'+
			'location: ', pos
		)
		def.resolve( pos )
	}

	if( navigator.geolocation ){
		navigator.geolocation.getCurrentPosition( success, failure )
	} else failure()

	return def.promise()
};
