import $ from 'jquery'
import React from 'react'
import {Component} from 'react'
import ReactDOM from 'react-dom'
import OTG_API from '/lib/otg-api'

import '/lib/geolocation'

import categorizeMarkets from '../../functions/categorize-markets'
import getUserLocation from '../../functions/get-user-location'

import LoadingMessage from '../markets-index-loading-message'
import LoadMore from '../load-more'
import Banner from '/js/components/banner'
import MarketsDisplay from './markets-display'
import MarketsNav from './markets-nav'

function getDistanceFromLatLonInMi(lat1, lon1, lat2, lon2) {
  //determines distance between two lat/lng locations in miles
  var p = 0.017453292519943295;
  var c = Math.cos;
  var a = 0.5 - c((lat2 - lat1) * p)/2 +
          c(lat1 * p) * c(lat2 * p) *
          (1 - c((lon2 - lon1) * p))/2;

  return 12742 * 0.621371 * Math.asin(Math.sqrt(a));
};
function fetchLocationsOnDate( date, query ){
  return new Promise(
    ( resolve, reject ) => {
      OTG_API.getLocations( query )
      .done(
        () => {
          OTG_API.getEvents( {
            dateFrom: date.setHours( 0, 0, 0, 0 ),
            dateTo: date.setHours( 23, 59, 59, 999 )
          } )
          .done(
            events => {
              let locations = events.getLocations()
              let promises = []
              locations.forEach(
                location => {
                  promises.push( location.fetchEvents() ) // needed to apply open status labels
                }
              )
              $.when( ...promises )
              .done( function(){ resolve( locations ) } )
              .fail( reject )
            }
          )
          .fail( reject )
        }
      )
    }
  )
}

export default class MarketsIndex extends Component {
  static defaultProps = {
    display: 'grid',
    showNav: true,
    markets: false,
    initialPageLength: 6,
  }
  constructor( props ){
    super(props)
    if( this.props.distanceFilterActive ){
      var dfa = true
    }
    else if( this.props.hideDistanceFilter ){
      var dfa = false
    } else {
      var dfa = this.props.showNav
    }
    var SF = {
      lat: 37.7749295,
      lng: -122.41941550000001,
      default: true
    }
    this.state = {
      markets: false,
      page: 0,
      prevPageLength: 0,
      status: 'all',
      distance: 10,
      clickThrough: {link: '', text: ''},
      display: this.props.display,
      pageLength: this.props.initialPageLength,
      distanceFilterActive: dfa,
      location: this.props.location || SF
    }
  }
  // Custom Methods
  changeDisplay = d => {
    var callback = d == 'list' ? this.getEvents : this.getMarkets
    this.setState( { markets: [], display: d }, callback )
  }
  classes = () => {
    var classes = [ "events-index", this.state.loading ]
    return classes.join( ' ' )
  }
  filterMarkets = callback => {
    var allMarkets = this.state.allMarkets
    var markets = [].concat( allMarkets.opennow, allMarkets.opentoday, allMarkets.closed )
    markets = markets.filter( m => {
      let conditions = [
        m.events,
        m.events.length > 0,
      ]
      if( this.props.excludeMarket ){
        let excludes = Array.isArray( this.props.excludeMarket ) ? this.props.excludeMarket : [ this.props.excludeMarket ]
        conditions.push( excludes.indexOf( m.slug ) === -1 )
      }
      return conditions.every( value => { return value } )
    })
    var pageStart = this.state.page * this.state.pageLength
    var pageEnd   = pageStart + this.state.pageLength
    var marketsToDisplay = this.state.display == 'map' ? markets : markets.slice( pageStart, pageEnd )
    this.setState( {
      firstLoadedMarket: marketsToDisplay[this.state.prevPageLength], //determines where to scroll in list when loading more markets
      markets: marketsToDisplay,
      totalPages: markets.length / marketsToDisplay.length
    }, callback )
  }
  getEvents = () => {
    this.setState( {loading:'loading'})
    var start = this.state.date ? this.state.date : new Date()
    var end = new Date( start.getTime() )
    end.setDate( end.getDate() + 7 )
    start.setHours( 0,0,0,0 )
    end.setHours( 0,0,0,0 )
    OTG_API.getEvents( {
      dateFrom: start.getTime(),
      dateTo: end.getTime()
    } )
      .done( ( events )=>{
        var promises = []
        events.forEach( function( event ){
          if( event.location ){
            promises.push( event.location.fetchDetails() )
          }
        })
        $.when.apply( null, promises )
          .always( ()=>{
            setTimeout( ()=>{
              // Was having errors where it seems like this was running before
              // the location data was properly added after fetchDetails(), so added the delay
              events = events.filter( ( event )=>{
                return OTG_API.Location.isAllowed( event.location )
              } )
              if( this.state.search ){
                events = events.filter( (event)=>{
                  var regex = new RegExp( this.state.search, 'i' );
                  return (
                    event.name.match( regex )             ||
                    event.locationName.match( regex )     ||
                    event.location.city.match( regex )    ||
                    event.location.address.match( regex )
                  )
                } )
              }
              var sorted = this.sortByDate(events)
              this.setState( {
                events: {
                  all: events,
                  byDate: sorted
                },
                loading: 'loading-complete'
              } )
            }, 200)
          } )
      })
  }
  getMarkets = () => {
    this.setState({loading: 'loading'})
    let date = this.state.date ? this.state.date : new Date()
    let locationsQuery = {
      latitude: parseFloat( this.state.location.lat ),
      longitude: parseFloat( this.state.location.lng ),
      radius: this.state.distanceFilterActive && this.state.display != 'map' ? this.state.distance : 13000
    }
    let locationsOnDatePromise = fetchLocationsOnDate( date, locationsQuery )
    if( this.state.date ){
      locationsOnDatePromise.then(
        ( locations )=>{
          this.setState(
            {
              allMarkets: categorizeMarkets( locations ),
              loading: 'loading-complete'
            },
            this.filterMarkets
          )
        }
      )
    } else {
      locationsOnDatePromise.then(
        locations => {
          switch( this.state.display ){
            case 'map':
              OTG_API.getLocations()
              .done( locations => {
                let allMarkets = categorizeMarkets( locations )

                let openMarkets = [].concat( allMarkets.opennow, allMarkets.opentoday);

                openMarkets.sort( (a, b) => a.events[0].startTime._date.ts - b.events[0].startTime._date.ts )

                let markets = [].concat( openMarkets, allMarkets.closed )

                this.setState({
                  allMarkets: allMarkets,
                  loading: 'loading-complete',
                  markets: markets,
                } )
              } )
              break;
            default:
              OTG_API.getLocations( locationsQuery )
              .done(
                locations => {
                  let promises = locations.map(
                    l => { return l.fetchEvents() }
                  )
                  $.when(...promises)
                  .done(
                    () => {
                      this.setState(
                        {
                          allMarkets: categorizeMarkets( locations ),
                          loading: 'loading-complete'
                        },
                        this.filterMarkets
                      )
                    }
                  )
                }
              )
          }
        }
      )
    }
  }
  determineIfInSacramento = () => {
    var sacramentoLocation = {
      lat: 38.575764,
      lng: -121.478851
    };

    //If/else user is within 30 miles of sacramento
    if (getDistanceFromLatLonInMi(this.state.location.lat, this.state.location.lng, sacramentoLocation.lat, sacramentoLocation.lng ) <= 30) {
      //selects sacramento in mailchimp form
      $('input[value="Sacramento"]').prop('checked', true);
    } else {
      //deselects sacramento in mailchimp form if not within 15 miles
      $('input[value="Sacramento"]').prop('checked', false);
    }
  }
  setCurrentMarket = market =>{
    this.setState( { currentMarket: market } )
  }
  setDate = date => {
    if( date.getTime() < Date.now() ){
      date = new Date()
    }
    this.setState( { date: date } )
    this.changeDisplay( this.state.display )
  }
  setLocation = location => {
    this.setState( { location: location }, this.getMarkets )
  }
  setPage = pageNumber => {
    this.setState(
      { page: pageNumber },
      this.filterMarkets
    )
    this.refs.parent.scrollIntoView()
  }
  setPageLength = newPageLength =>{
    this.setState( { pageLength: newPageLength, prevPageLength: newPageLength / 2 }, this.filterMarkets );
  }
  sortByDate = events => {
    var byDate = {}
    events.forEach( function( event ){
      if( byDate[ event.date ] == undefined ) byDate[ event.date ] = []
      byDate[ event.date ].push( event )
    })
    return byDate
  }
  updateDistance = e => {
    this.setState({
      distance: e.target.value,
      distanceFilterActive: true
    }, this.getMarkets);
  }
  updateSearch = search => {
    this.setState( { search: search }, this.getEvents )
  }
  toggleDistanceFilter = () => {
    this.setState({
      distanceFilterActive: !this.state.distanceFilterActive
    }, this.getMarkets);
  }
  getClickThrough = () => {
    const wrap = document.querySelector('.events-index-wrap');
    if (wrap) {
      const clickThrough = {
        text: wrap.dataset ? wrap.dataset.clickthroughText : '',
        link: wrap.dataset ? wrap.dataset.clickthroughLink : '',
        additional: wrap.dataset ? wrap.dataset.clickthroughAdditional : undefined
      };
      this.setState( { clickThrough: clickThrough } )
    }
  }

  // React Methods
  componentDidUpdate( prevProps, prevState ){
    if(
      this.state.location && prevState.location &&
      this.state.location.lat != prevState.location.lat &&
      this.state.location.lng != prevState.location.lng
    ){
      this.determineIfInSacramento()
    }
  }
  componentDidMount(){
    this.getClickThrough()

    OTG_API.getVendors() // preload vendor data
      .done( function(){
        if( this.props.locations ){
          var allLocations = categorizeMarkets( this.props.locations );
          this.setState(
            { allMarkets: allLocations, loading: 'loading-complete' },
            function(){
              this.filterMarkets(
                $(this.refs.parent).trigger.bind( $(this.refs.parent ),
                'eventsIndexLoadingComplete' )
              )
            }
          )
        } else {
          if( this.state.location.default ){
            var promise = getUserLocation()
            promise
              .fail( ()=>{this.changeDisplay( this.state.display)} )
              .done( function( location ){
                this.setState({ location: location }, ()=>{this.changeDisplay( this.state.display)} )
              }.bind(this))
            setTimeout(
              function(){
                if( promise.state() == "pending" ) ()=>{this.changeDisplay( this.state.display)}
              }.bind(this),
              5000
            )
          } else {
            this.getMarkets()
          }
        }
      }.bind( this ))
  }
  render(){
    return (
      <div ref="parent" className={this.classes()}>
        {
          this.props.showNav && <div className="search-filter-wrap">
            <MarketsNav
              headingText={ this.props.headingText }
              hideDistanceFilter={ this.state.display == 'map' || this.props.hideDistanceFilter}
              setDate={this.setDate}
              date={this.state.date}
              distance={this.state.distance}
              display={this.state.display}
              changeDisplay={this.changeDisplay}
              updateMarkets={this.setLocation}
              updateSearch={this.updateSearch}
              status={this.state.status}
              updateDistance={this.updateDistance}
              distanceFilterActive={this.state.distanceFilterActive}
              toggleDistanceFilter={this.toggleDistanceFilter}
              location={this.state.location}
              setCurrentMarket={this.setCurrentMarket}
              searchButtonOnClick={this.props.searchButtonOnClick}
            />
          </div>
        }
        { typeof MarketOptions !== 'undefined' &&
          <Banner { ...MarketOptions['textBanner'] } />
        }
        <MarketsDisplay
          ref="display"
          allMarkets={this.state.allMarkets ? this.state.allMarkets.all : false }
          currentMarket={ this.state.currentMarket }
          date={this.state.date}
          display={this.state.display}
          distanceFilterActive={this.props.showNav && this.state.distanceFilterActive}
          events={this.state.events}
          filterListByMapBounds={this.props.filterListByMapBounds}
          firstLoadedMarket={this.state.firstLoadedMarket}
          getMarkets={this.getMarkets}
          location={this.state.location}
          markets={this.state.markets}
          message={this.state.message}
          nextWeek={this.state.nextWeek}
          setCurrentMarket={ this.setCurrentMarket }
          setDate={this.setDate}
          clickThrough={this.state.clickThrough}
        />
        <LoadingMessage status={this.state.loading} />
        {
          ( ! window.innerWidth < 768 ) &&
          this.state.display === 'grid' &&
          this.state.loading === 'loading-complete'
          &&
          <LoadMore
            setPageLength={this.setPageLength}
            totalPages={this.state.totalPages}
            pageLength={this.state.pageLength}
          />
        }
      </div>
    )
  }
}
