import $ from 'jquery'
import { API_DOMAIN, OTG_API_JWT } from './config'

let OTG_API = {}

	var cache = {}

	OTG_API.get = function( path, data ){
		var index = path + JSON.stringify( data )
		if( typeof cache[ index ] != 'undefined' ){
			return cache[ index ]
		}else{
			var promise =  $.get({
				url: API_DOMAIN + path,
				headers: { 'Authorization': OTG_API_JWT },
				data: data,
				traditional: true
			})
			cache[ index ] = promise
			return promise
		}
	}

export default OTG_API
