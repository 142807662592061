import $ from 'jquery'
import OTG_API from '/lib/otg-api'
import React from 'react'
import toClassName from '/functions/to-class-name'
import getImageSize from '/functions/get-image-size'

import {Component} from 'react'
import LoadMore from '../load-more'

class Vendor extends Component {
  render(){
    var src = ( this.props.vendor.logoUrl ) ? getImageSize( '--small', this.props.vendor.logoUrl ) : THEME_DIR_URI + "/images/placeholder-truck.svg"

    var srclife = ''
    if( this.props.vendor.bannerUrl ){
      srclife = getImageSize( '--small', this.props.vendor.bannerUrl )
    }

    return (
      <li className="grid-item-card vendors-grid-item">
        <a href={this.props.permalink} >
          <article>
            <div className="lifestyle-wrap card__image">
              <div className="lifestyle-img" style={{ backgroundImage: 'url(' + srclife + ')' }}></div>
              <div className="logo-wrap">
                <div className="logo-img vendor-logo" style={{ backgroundImage: 'url(' + src + ')' }}></div>
              </div>
            </div>
            <div className="content-wrap" >
              <header>
                <h3>{this.props.name}</h3>
                <section className="food-cat highlight">
                  {this.props.vendor.primaryCuisine}
                </section>
              </header>
            </div>
          </article>
        </a>
      </li>
    )
  }
}

class VendorsNav extends Component {
  state = {
    'searchname': '',
    'searchcuisine' : '',
    'order': 'asc',
  }
  applyFilters = () => {
    var filters = {
      'searchname': this.refs.nameinput.value,
      'searchcuisine': this.state.cuisineFilter,
      'order': this.refs.searchorder.value,
    }
    this.props.setFilters( filters );
  }
  updateCuisineFilter = search => {
    this.setState( { cuisineFilter: search })
    this.applyFilters()
  }
  render(){
    return false && (
      <div className="search-bar">
        <input
          ref="nameinput"
          className="search"
          placeholder="Search"
          onKeyUp={ function(e){
            if( e.keyCode == 13 ) this.applyFilters()
          }.bind(this) }
        />
        <CuisineInput updateFilter={this.updateCuisineFilter} cuisines={this.props.cuisines}  />
        <button
          onClick={this.applyFilters}
          className="button search-bar__button vendors-index__search-button"
        >
          Search
        </button>
        <select
          ref="searchorder"
          onChange={this.applyFilters}
          className="sort"
        >
          <option>Sort &#9662;</option>
          <option value="asc">A-Z</option>
          <option value="desc">Z-A</option>
        </select>
      </div>
    )
  }
}
class CuisineInput extends Component {
  state = { focus: false }
  static defaultProps = { cuisines: [] }
  focus = ()=>{
    this.setState( { focus: true } )
  }
  selectValue = value => {
    this.refs.cuisineinput.value = value
    this.props.updateFilter( value )
    this.setState( { focus: false } )
  }
  theOptions = () => {
    var items = [
      <li
        key="0"
        onClick={ this.selectValue.bind(this, "") }
        >Any Cuisine</li>
    ]
    var cuisines = this.props.cuisines
    if( cuisines.length < 1 ) cuisines.push('loading…')
    this.props.cuisines.forEach( function( c, i ){
      items.push(
        <li
        key={++i}
        onClick={ this.selectValue.bind( this, c ) } >
          {c}
        </li>
      )
    }.bind(this) )
    return items
  }
  updateFilter = () => {
    this.props.updateFilter( this.refs.cuisineinput.value )
  }
  componentDidMount(){
    $('body').click( function(e){
      if( 0 == $(this.refs.parent).has( e.target ).length ){
        this.setState( { focus: false } )
      }
    }.bind(this))
  }
  render(){
    return (
      <div ref="parent" className="cuisine-input dropdown-input">
        <input
          ref="cuisineinput"
          className="cuisine"
          placeholder="Cuisine &#9662;"
          onChange={this.updateFilter}
          onFocus={ this.focus }
        />
        { this.state.focus ?
          <ul className="cuisine-options">
            {this.theOptions()}
          </ul> : false
        }

      </div>
    )
  }
}

class VendorsGrid extends Component {
  theClasses() {
    var classes = [
      'vendors-grid',
      this.props.compact ? 'vendors-grid-compact grid-cards-get-mini' : 'vendors-grid-large grid-cards-get-mini'
    ]
    return classes.join( ' ' )
  }
  getVendors = () => {
    if( this.props.vendors.length == 0 ){
      if( this.props.loadingComplete)
        return <div className="message message_search none-found">No food Schedule found. Try searching again?</div>
      else
        return <div className="message message_loading loading loading-vendors">Loading Food Schedule&#8230;</div>
    }

    if( this.props.compact )
      return(
        this.props.vendors.map( function( v ){
          return <CompactVendor key={v.id} vendor={v} />
        })
      )
    return this.props.vendors.map(  v =>{
      return <Vendor
        key={v.id}
        compact={this.props.compact}
        vendor={v}
        id={v.id}
        name={v.name}
        permalink={v.permalink}
      />
    })
  }
  render() {
    return <ul className={this.theClasses()}>{this.getVendors()}</ul>;
  }
}

class CompactVendor extends Component {
  componentDidMount(){
    if( this.props.vendor && this.props.vendor.primaryCuisine == undefined ){
      this.props.vendor.fetchCuisines().done( function(){
        this.forceUpdate()
      }.bind(this) )
    }
  }
  render(){
    var vendor = this.props.vendor
    const order = vendor.orderAheadURL
    return (
      <li className="grid-item-card">
        <span className="vendor">
          <article>
            <div
              className="logo-img"
              style={	{
                backgroundImage: 'url(' + ( getImageSize('--small', vendor.logoUrl) )+')'}
              } />
            <div className="content-wrap">
              <header>
                <a className={`${order ? 'card-link-normal' : 'card-link-expanded'}`} href={ vendor.permalink }>
                  <h3>{vendor.name}</h3>
                </a>
                <section className="food-cat highlight">
                  <span>{vendor.primaryCuisine}</span>
                </section>
              </header>
              <aside>
              { order &&
                    <a href={order} className="button--bordered button--lg"
                      style={{
                        display: 'block',
                        margin: '1em 2em',
                      }}>
                      Order Online
                    </a>
                  }
              </aside>
            </div>
          </article>
        </span>
      </li>
    )
  }
}

function areSameVendors( v1, v2 ){
  if( v1.length !== v2.length ) return false
  let prev = v1;
  let curr = v2;

  prev.sort();
  curr.sort();

  prev = JSON.stringify(prev)
  curr = JSON.stringify(curr)

  return prev === curr
}

export default class extends Component {
  state = {
    allVendors: [],
    vendors: [],
    filteredVendors: [],
    currentPage: 0,
    pageLength: this.props.initialPageLength ? this.props.initialPageLength : 6,
    searchname: '',
    searchcuisine: '',
    loadingComplete: false
  }
  filterVendors = () => {
    if( ! this.state.allVendors ) return []
    var filteredvendors = this.state.allVendors.slice();
    var cuisines = []
    var i = 0;

    filteredvendors = filteredvendors.filter( function( vendor, i ){
      console.log(vendor)
      var removeVendor = false;
      if( this.state.searchname ) {
        var regex = new RegExp( this.state.searchname, 'i' );
        removeVendor = ! vendor.name.match( regex );
      }
      if( ! removeVendor ){
        if( vendor.cuisine_type && cuisines.indexOf( vendor.cuisine_type ) === -1 )
          cuisines.push( vendor.cuisine_type )
        else if( vendor.cuisine && cuisines.indexOf( vendor.cuisine ) === -1 )
          cuisines.push( vendor.cuisine )
      }
      if( ! removeVendor && this.state.searchcuisine ) {
        var regex = new RegExp( this.state.searchcuisine, 'i' );
        if( vendor.cuisine_type )
          removeVendor = ! vendor.cuisine_type.match( regex );
        else if( vendor.cuisine )
          removeVendor = ! vendor.cuisine.match( regex )
        else
          removeVendor = true // has no cuisine property
      }

      return ! removeVendor
    }.bind(this) )

    cuisines.sort();

    var sortorder = this.state.order;
    filteredvendors.sort(function(x, y) {
      var xcomp = x.name ? x.name.toLowerCase().trim() : 0
      var ycomp = y.name ? y.name.toLowerCase().trim() : 0

      if( sortorder === 'desc' ) {
        return xcomp > ycomp ? -1 : 1;
      } else {
        return xcomp < ycomp ? -1 : 1;
      }
    });

    var pageStart = this.state.currentPage * this.state.pageLength
    var pageEnd   = pageStart + this.state.pageLength
    this.setState( {
      'filteredVendors': filteredvendors,
      'vendors': filteredvendors.slice( pageStart, pageEnd ),
      'cuisines': cuisines
    } )
  }
  getVendors = () => {
    if( this.props.vendors ){

      this.setVendors(this.props.vendors)
      this.setLoadingComplete()

    } else {

      OTG_API.getVendors()
        .done(
          function( vendors ){

            var promises = []
            vendors.forEach( function( v, i ){
              promises.push( v.fetchCuisines() )
            })
            $.when.apply( null, promises ).always( function(){
              
              this.setVendors( vendors )
              
              this.setLoadingComplete()

            }.bind(this) )
          }.bind(this)
        )
        .fail( this.setLoadingComplete )
    }
  }
  setEventDate = date =>{
    this.setState( { eventDate: date } )
    history.replaceState( {}, '', date )
  }
  setFilters = filters => {
    this.setState( filters, function() {
      this.filterVendors();
    });
  }
  setLoadingComplete = () => {
    this.setState( { loadingComplete: true } )
  }
  setPage = pageNum => {
    var pageStart = pageNum * this.state.pageLength
    var pageEnd   = pageStart + this.state.pageLength
    this.refs.parent.scrollIntoView()
    this.setState(
      {
        currentPage: pageNum,
        vendors: this.state.filteredVendors.slice( pageStart, pageEnd )
      }
    )
  }
  setPageLength = newPageLength =>{
    this.setState( {
      pageLength: newPageLength,
      vendors: this.state.filteredVendors.slice( 0, newPageLength )
    } )
  }
  setVendors = vendors => {
    this.setState({
      'allVendors': vendors,
    }, function() {
      this.filterVendors();
    });
  }
  totalPages = () => {
    return Math.ceil( this.state.filteredVendors.length / this.state.pageLength )
  }
  theClasses = () =>{
    return toClassName( ['vendors-index', this.state.loadingComplete ? 'loading-complete' : 'loading'])
  }
  componentDidUpdate( prevProps, prevState ){
    if(
      ( this.props.event && prevProps.event && this.props.event.id != prevProps.event.id )
      ||
      ( this.props.vendors && ! areSameVendors( this.props.vendors, prevProps.vendors ) )
      ){
      this.getVendors()
    }
  }
  componentDidMount(){
    OTG_API.getVendors() // preload vendor data
      .done( this.getVendors )
  }
  render(){
    return (
      <div ref="parent" className={this.theClasses()}>
        <VendorsNav
          setFilters={this.setFilters}
          cuisines={this.state.cuisines}
        />
        <VendorsGrid
          compact={ this.props.compactGrid }
          vendors={ this.state.vendors }
          loadingComplete={ this.state.loadingComplete }
        />

        <LoadMore
          totalPages={this.totalPages()}
          pageLength={this.state.pageLength}
          setPageLength={this.setPageLength}
        />
      </div>
    )
  }
}
