import React from 'react'
import {Component} from 'react'
export default class extends Component{
  clicked = event => {
    this.props.setPageLength( this.props.pageLength * 2 )
  }
  render(){
    if( this.props.totalPages <= 1 )
      return false
    return (
      <div className="wrap-load-more">
        <a className="button button--lg" onClick={this.clicked}>SEE MORE</a>
      </div>
    )
  }
}
