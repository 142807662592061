import React from 'react'
import { css } from 'glamor'
import { space } from '../../variables'
import DateChooser from '../date-chooser'
import AddressSearch from './address-search'
import DistanceFilter from './distance-filter'
import MarketsSearch from './markets-search'
import SearchButton from './search-button'

import getAddressLocation from '../../functions/get-address-location'

const svgGrid = <svg x="0px" y="0px" viewBox="0 0 23 23" style={{enableBackground:'new 0 0 23 23'}} >
	<path d="M13.6,5.9H9.3C9.1,5.9,9,5.7,9,5.5V1.4C9,1.2,9.1,1,9.3,1h4.3C13.8,1,14,1.2,14,1.4v4.1 C14,5.7,13.8,5.9,13.6,5.9z"/>
	<path d="M13.6,13.9H9.3c-0.2,0-0.4-0.2-0.4-0.4V9.4c0-0.2,0.2-0.4,0.4-0.4h4.3c0.2,0,0.4,0.2,0.4,0.4v4.1 C14,13.8,13.8,13.9,13.6,13.9z"/>
	<path d="M13.6,22H9.3C9.1,22,9,21.8,9,21.6v-4.1c0-0.2,0.2-0.4,0.4-0.4h4.3c0.2,0,0.4,0.2,0.4,0.4v4.1 C14,21.8,13.8,22,13.6,22z"/>
	<path d="M5.6,5.9H1.4C1.2,5.9,1,5.7,1,5.5V1.4C1,1.2,1.2,1,1.4,1h4.3C5.9,1,6,1.2,6,1.4v4.1C6,5.7,5.9,5.9,5.6,5.9z"/>
	<path d="M5.6,13.9H1.4c-0.2,0-0.4-0.2-0.4-0.4V9.4c0-0.2,0.2-0.4,0.4-0.4h4.3C5.9,9.1,6,9.2,6,9.4v4.1 C6,13.8,5.9,13.9,5.6,13.9z"/>
	<path d="M5.6,22H1.4C1.2,22,1,21.8,1,21.6v-4.1c0-0.2,0.2-0.4,0.4-0.4h4.3c0.2,0,0.4,0.2,0.4,0.4v4.1 C6,21.8,5.9,22,5.6,22z"/>
	<path d="M21.6,5.9h-4.3c-0.2,0-0.4-0.2-0.4-0.4V1.4C17,1.2,17.1,1,17.4,1h4.3C21.8,1,22,1.2,22,1.4v4.1 C22,5.7,21.8,5.9,21.6,5.9z"/>
	<path d="M21.6,13.9h-4.3c-0.2,0-0.4-0.2-0.4-0.4V9.4c0-0.2,0.2-0.4,0.4-0.4h4.3c0.2,0,0.4,0.2,0.4,0.4v4.1 C22,13.8,21.8,13.9,21.6,13.9z"/>
	<path d="M21.6,22h-4.3c-0.2,0-0.4-0.2-0.4-0.4v-4.1c0-0.2,0.2-0.4,0.4-0.4h4.3c0.2,0,0.4,0.2,0.4,0.4v4.1 C22,21.8,21.8,22,21.6,22z"/>
</svg>

const svgMap = <svg className="icon-location" viewBox="0 0 21 27" >
	<path
		d="M15.5381453,10.1543198 C15.5381453,13.1474036 13.1091747,15.5833169 10.1246215,15.5833169 C7.13931148,15.5833169 4.71109782,13.1474036 4.71109782,10.1543198 C4.71109782,7.16123591 7.13931148,4.72532261 10.1246215,4.72532261 C13.1091747,4.72532261 15.5381453,7.16123591 15.5381453,10.1543198 M10.1246215,0 C4.54154674,0 0,4.55528691 0,10.1543198 C0,11.0310664 0.304283632,12.6160421 0.330775988,12.7344598 C0.430690016,13.0525176 0.680475087,13.8336192 0.842456921,14.2025359 C3.76115763,21.8807107 9.82487945,26.8664005 9.88619033,26.9157412 C9.95582738,26.9719137 10.039846,27 10.1246215,27 C10.2086402,27 10.2926588,26.9719137 10.3622958,26.9157412 C10.4236067,26.8664005 16.4880854,21.8807107 19.4007308,14.2184768 C19.5922326,13.7766876 19.8980301,12.7633051 19.9146825,12.7063735 C20.1364613,11.8486041 20.25,10.9908347 20.25,10.1543198 C20.25,4.55528691 15.7076963,0 10.1246215,0"
		id="LOCATION" />
</svg>


const svgList = <svg viewBox="0 0 24 24">
	<path d="M0,6H2V4H0Zm0,4H2V8H0ZM0,2H2V0H0ZM4,6H18V4H4Zm0,4H18V8H4ZM4,0V2H18V0Z" transform="translate(3 7)"/>
</svg>

const styles = {
  margin: `0 0 ${space} 0`,
}

export default class MarketsNav extends React.Component {
	static defaultProps = { headingText: 'Search Markets', searchButtonOnClick: function(){} }
	state = { address: '' }
	changeDisplay( d ){
		this.props.changeDisplay( d )
	}
	updateAddress( event ){
		this.setState( { 'address': event.target.value })
	}
	updateMarkets(){
		var request = getAddressLocation( this.state.address )
		this.props.setCurrentMarket( undefined )
		request.done( this.props.updateMarkets )
	}
	render(){
		var src = ( this.props.image ) ? this.props.image : THEME_DIR_URI + "/images/placeholder-truck.svg"
		var srcmap = ( this.props.mapicon ) ? this.props.mapicon : THEME_DIR_URI + "/images/icon-location.svg" // I think we can remove these as theyve been replaced by the below
		var srcgrid = ( this.props.gridicon ) ? this.props.gridicon : THEME_DIR_URI + "/images/icon-grid.svg" // I think we can remove these as theyve been replaced by the below

		return (
			<div className={ `markets-nav search-bar location-filter ${ css( styles )}`}>
				<h3>{this.props.headingText}</h3>
				<div className="search-wrap">
					{ this.props.display == 'list' &&
						<MarketsSearch updateSearch={this.props.updateSearch }/> }
					{ this.props.display != 'list' &&
						<AddressSearch updateAddress={this.updateAddress.bind(this)} value={this.state.address} updateMarkets={this.updateMarkets.bind(this)} /> }
					{ this.props.display != 'list' &&
						<SearchButton
							updateMarkets={this.updateMarkets.bind(this)}
							onClick={this.props.searchButtonOnClick.bind(this)}
						/>
					}
				</div>
				<DateChooser setDate={this.props.setDate} status={this.props.status} date={this.props.date} />
				<div className="view-buttons">
					<div
						className={ this.props.display == "grid" ? "current view-grid" : "view-grid" }
						onClick={this.changeDisplay.bind( this, 'grid' ) }>
						{svgGrid}
						<span>Grid</span>
					</div>
					<div
						className={ this.props.display == "map" ? "current view-map" : "view-map" }
						onClick={ this.changeDisplay.bind( this, 'map' ) }
						>
						{svgMap}
						<span>Map</span>
					</div>
					<div
						className={ this.props.display == "list" ? "current view-list" : "view-list" }
						onClick={ this.changeDisplay.bind( this, 'list' ) }
						>
						{svgList}
						<span>List</span>
					</div>
				</div>
				{ ! this.props.hideDistanceFilter && this.props.display != 'list' && <DistanceFilter
					distance={this.props.distance}
					updateDistance={this.props.updateDistance}
					distanceFilterActive={this.props.distanceFilterActive}
					toggleDistanceFilter={this.props.toggleDistanceFilter}
					location={this.props.location}
				/> }
			</div>
		)
	}
}
