/**
 * TODO: SHOULD BE DEPRECATED
 * This class should no longer be used. Deprecation notices should be added to all these methods
 * and Luxon should be used directly instead.
 * Also, Luxon is loaded in this plugin and in the theme separately as node modules. It should
 * either be loaded externally, or this codebase should be moved into the theme files, so they
 * can depend on the same module.
 */

import $ from 'jquery'
import {DateTime} from 'luxon'

	const TIMEZONE = 'America/Los_Angeles'

	let OTGDate = function( ISOString ){
    this._date = DateTime.fromISO(ISOString, { setZone: true })
	}

	OTGDate.prototype.get12Hours = function(){
    return this._date.toFormat( 'h' )
	}
	OTGDate.prototype.getDate = function(){
    return this._date.toFormat( 'd' )
	}
	OTGDate.prototype.getDateTime = function(){
		var date = [
			this.getFullYear(),
			this.getMonth(),
			this.getDate()
		].join('-')
		return date + '-' + this.toFormat( 'ha' )
	}
  OTGDate.prototype.getFullYear = function(){
    return this._date.toFormat( 'yyyy' )
  }
	OTGDate.prototype.getMonth = function(){
    return this._date.toFormat( 'M' )
	}
	OTGDate.prototype.getWeekDay = function( args ){
    return this._date.toFormat( 'EEE' )
	}
	OTGDate.prototype.toDateString = function(){
    return this._date.toLocaleString( 'en-US' )
	}

  /* Passthrough methods
   * These pass directly to the corresponding Luxon methods.
   * When only the below functions are used, the OTGDate object can be directly replaced with a
   * Luxon DateTime object.
  */
  OTGDate.prototype.toFormat = function( format, opts ){
    return this._date.toFormat( format, opts )
  }
  OTGDate.prototype.valueOf = function(){
    return this._date.valueOf()
  }

export default OTGDate
