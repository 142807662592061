import React from 'react'
import createReactClass from 'create-react-class'

export default createReactClass({
	render: function(){
		return (
			<header className="page-header error" style={{ backgroundImage: 'url(' + THEME_DIR_URI + '/images/awesometruckfood.jpg' + ')' }} >
				<div className="content">
					<h1>Oops. Page Not Found</h1>
					<a className="button button-big" href="/events/"> Check Out Our Events </a>
				</div>
			</header>
		)
	}
})
