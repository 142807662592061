import React from 'react'
import Upcoming from '../upcoming'
import './market-card.scss'

const View = ({ clicked, m, Header, style, onClick, getCardImage, hideVendorList, events }) => (
  <article onClick={clicked} className={`market-card market-card--${m.status}`} style={style}>
    { style &&
      <style dangerouslySetInnerHTML = { {__html: `
        .market-card:after { background-color: ${style.highlightColor}}
        .market-card .market-card__highlight,
        .market-card .upcoming__day,
        .market-card .see-vendors-list,
        .market-card a {
          color: ${ style.highlightColor }
        }
      `}} />
    }
    { ! onClick &&
      <a href={ m.permalink } >
        {getCardImage()}
        {Header}
      </a>
    }
    { onClick && getCardImage() }
    { onClick && Header }
    <section className="content">
      <p className="event-where">{m.address}, {m.city}</p>
      { events ?
        <Upcoming market={m} events={events} hideVendorList={ hideVendorList } /> :
        <div>loading events…</div>
      }
    </section>
  </article>
)

export default View
