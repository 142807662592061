import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import GetOverlay from './overlay'

export class Popup extends React.Component {

  onAdd = ()=>{
    if( this.overlay && this.props.position ){
      let { position, map } = this.props
      let newPosition = position instanceof google.maps.LatLng ? position : new google.maps.LatLng( position.lat, position.lng )
      let divPosition = this.overlay.getProjection().fromLatLngToDivPixel( newPosition );
      divPosition.y = divPosition.y - 200
      newPosition = this.overlay.getProjection().fromDivPixelToLatLng( divPosition )
      map.panTo( newPosition )
    }
  }
  componentDidMount() {
    const Overlay = GetOverlay( google )
    this.overlay = new Overlay( { onAdd: this.onAdd, ...this.props} )
    this.overlay.setMap( this.props.map )
  }

  componentDidUpdate(prevProps) {
    const { children, google, map, position } = this.props;
    let newPosition = position instanceof google.maps.LatLng ? position : new google.maps.LatLng( position.lat, position.lng )
    let oldPosition = prevProps.position instanceof google.maps.LatLng ? prevProps.position : new google.maps.LatLng( prevProps.position.lat, prevProps.position.lng )

    if (!google || !map) {
      return;
    }

    if (map !== prevProps.map) {
      this.overlay.setMap( map )
    }
    if( ! newPosition.equals( oldPosition ) ){
      this.overlay.position = newPosition
      let divPosition = this.overlay.getProjection().fromLatLngToDivPixel( newPosition );
      divPosition.y = divPosition.y - 200
      newPosition = this.overlay.getProjection().fromDivPixelToLatLng( divPosition )
      map.panTo( newPosition )
    }
    if ( children !== prevProps.children) {
      this.overlay.children = children
    }
  }
  componentWillUnmount(){
    if( this.overlay ) this.overlay.setMap( null );
  }
  render() {
    return null;
  }
}

Popup.propTypes = {
  children: PropTypes.element.isRequired,
  map: PropTypes.object,
  marker: PropTypes.object,
  position: PropTypes.object,

  // callbacks
  onClose: PropTypes.func,
  onOpen: PropTypes.func
}

Popup.defaultProps = {
}

export default Popup
