import React from 'react'
import { css } from 'glamor'
import { space } from '/js/variables'
import { teal, tealDark } from '/js/variables/colors'
import { knockout } from '/js/variables/fonts'
import MaybeWrap from '/js/components/maybewrap'

const styles = {
  color: 'white',
  background: teal,
  fontSize: 20,
  textTransform: 'uppercase',
  letterSpacing: '.04em',
  margin: `0 0 ${space} 0`,
  padding: '9px 20px',
  transition: 'all .3s ease-in-out',
  fontFamily: knockout,
}

const hover = {
  '&:hover': {
    background: tealDark,
  }
}

const Banner = ({ message, url, target, title, align }) => (
  message ?
    <MaybeWrap
      condition={ url }
      Component={ 'a' }
      href={ url }
      target={ target }
      title={ title }
      >
      <div className={ url ? css( styles, hover, { textAlign: align } ) : css( styles, { textAlign: align } ) }>
        { message }
      </div>
    </MaybeWrap>
  : null
)

export default Banner
