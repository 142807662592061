import $ from 'jquery'
let OTG_API = {}


if( typeof MENU_API_DOMAIN != 'undefined' ){
	OTG_API.MenuEndpoint = MENU_API_DOMAIN
}

	function Menu( data ){
		var def = $.Deferred()
		var methods = {
			constructItems: function( items ){
				return items.map( function( item ){
					return new Menu.Item( item )
				})
			}.bind(this),
			fetchItems: function(){
				var def = $.Deferred()
				$.get( OTG_API.MenuEndpoint + '/menus/' + this.id + '/items' )
				.done( function( response ){
					def.resolve( response.data.items )
				})
				return def.promise()
			}.bind(this)
		}
		$.extend( this, data )
		methods.fetchItems().done(
			function( items ){
				var promises = methods.constructItems( items )
				$.when.apply('null', promises ).done( function(){
					this.items = Array.prototype.slice.call(arguments)
					def.resolve( { status: 'menuComplete', menu: this } )
				}.bind(this))
				def.notify( { status: 'itemsLoaded' } )
			}.bind(this)
		)
		this.promise = def.promise()
	}
	Menu.Item = function( data ){
		var def = $.Deferred()
		var methods = {
			fetchCategory: function(){
				var def = $.Deferred()
				OTG_API.getMenuCategory( this.category.id ).promise
				.done( function( category ){
					def.resolve( category )
				} )
				return def.promise()
			}.bind( this ),
			fetchDietaries: function(){
				var def = $.Deferred()
				var promises = []
				var dietaries = []

				this.dietaries.forEach(
					function( dietary ){
						var url = OTG_API.MenuEndpoint + '/dietary_requirements/' + dietary.id
						var promise = $.get( url )
						promises.push( promise )
						promise.done( function( response ){
							response.data.forEach( function( d ){
								dietaries.push( d.description )
							} )
						})
					}
				)
				$.when.apply( null, promises ).done( function(){
					def.resolve( dietaries )
				}.bind(this))
				return def.promise()
			}.bind(this)
		}
		$.extend( this, data )
		var dpromise = methods.fetchDietaries()
			.done(
				function( dietaries ){
					this.dietaries = dietaries
					def.notify( { status: 'dietaries loaded', item: this } )
				}.bind(this)
			)
		// var cpromise = methods.fetchCategory()
		// 	.done(
		// 		function( category ){
		// 			this.category = category
		// 		}.bind(this)
		// 	)
		$.when( dpromise ).done( function(){
			def.resolve( this )
		}.bind(this))
		return def.promise()
	}

	Menu.Category = function( id ){
		/* this endpoint accepts an array of ids, so this could be optimized
		 * to get data for multiple categories at once
		 */
		this.id = id
		var def = $.Deferred()
		var endpointURL = OTG_API.MenuEndpoint + "/categories/" + id
		$.get( endpointURL )
		.done( function( response ){
			$.extend( this, response.data[0] )
			def.resolve( this )
		}.bind(this) )
		this.promise = def.promise()
	}

	var categoryObjects = {}
	OTG_API.getMenuCategory = function( id ){
		if( categoryObjects[id] !== undefined ){
			return categoryObjects[id]
		}else{
			return categoryObjects[id] = new Menu.Category( id )
		}
	}
	OTG_API.getMenus = function( vendorID ){
		var def = $.Deferred()
		var promises = []
		var _menus = []
		var methods = {
			fetchMenus: function(){
				var def = $.Deferred()
				$.get( OTG_API.MenuEndpoint + '/menus?vendor=' + vendorID )
					.done( function( response ){
						def.resolve( response.data.menus )
					} )
					.fail( function( response ) {
						def.reject( response )
					} )
				return def.promise()
			},
			constructMenus: function( menus ){
				return menus.map(
					function( menu ){
						var m = new Menu( menu )
						promises.push( m.promise )
						return m
					}
				)
			}
		}
		$.extend( this, methods )

		this.fetchMenus()
			.done(
				function( menus ){
					_menus = this.constructMenus( menus )
					def.notify( { status: 'menusLoaded', menus: _menus } )
					_menus.forEach( function( menu, index ){
						promises.push( menu.promise )
						menu.promise.done( function( response ){
							def.notify( {
								status: 'menuComplete',
								index: index,
								menus: _menus
							} )
						}.bind(this) )
						$.when.apply( 'null', promises ).done(
							function(){
								def.resolve( { status: 'menusComplete', menus: _menus } )
							}
						)
					}.bind(this) )
				}.bind(this)
			)
			.fail(
				function( response ){
					def.reject( response.responseJSON )
				}
			)
		return def.promise()
	}

export default OTG_API
