import $ from 'jquery'
import OTG_API from './otg-api-get'
import Location from './location'



  OTG_API.Location = Location

  OTG_API.getLocation = function( id ){
    var def = $.Deferred()
    var l = new Location( {id:id} )
    l.fetchDetails()
      .done( response => { def.resolve(l) } )
      .fail( def.reject  )
    return def.promise()
  }
  OTG_API.getLocations = function( data ){
    var def = $.Deferred()
    OTG_API.get( '/locations', data )
      .done( function( response ){
        let locations = []
        response.data.locations.forEach( function( data ){
          if( Location.isAllowed( data ) ){
            locations.push( new Location( data ) )
          }
        })
        def.resolve( locations )
      })
    return def.promise()
  }

  export default OTG_API
