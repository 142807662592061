import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import toClassName from '../../../functions/to-class-name'
import Market from '../../market-card'
import View from './view'

function preloadImage(url){
  var img=new Image();
  img.src=url;
  return img
}
function preloadMapIcons(){
  ['blue','yellow','grey'].forEach( function( color ){
    var url = THEME_DIR_URI + '/images/mapmarks/' + color + '/' + color + '.png'
    preloadImage( url )
  } )
  preloadImage( THEME_DIR_URI + '/images/truck.svg')
}

export default class MarketsMap extends React.Component {
  get marketsInBounds(){
    let bounds = this.state.currentBounds
    if( ! bounds ) return []
    return this.props.markets.filter( market => {
      return bounds.contains( { lat: market.latitude, lng: market.longitude } )
    } )
  }
  state = { markets: [] }
  onBoundsChanged = ( props, map ) => {
    this.setState( { currentBounds: map.getBounds() }, this.setMarkets )
  }
  setMarkets = () => {
    let promises = []
    let mib = this.marketsInBounds
    mib.forEach(
      market => {
        promises.push( market.fetchEvents() )
      }
    )
    $.when( null, ...promises )
    .done(
      () => {
        mib = mib.filter(
          market => ( Array.isArray( market.events ) && market.events.length > 0 )
        )
        this.setState( { markets: mib } )
        this.props.setMarketsInBounds( mib )
      }
    )
  }
  componentDidMount(){
    preloadMapIcons()
  }
  render(){
    return (
      <View
        currentMarket={ this.props.currentMarket }
        location={ this.props.location }
        onBoundsChanged={ this.onBoundsChanged }
        onReady={ this.onBoundsChanged }
        markets={ this.state.markets }
        setCurrentMarket={ this.props.setCurrentMarket }
      />
    )
  }
}
