
export default function( address, callback ) {
	var def = jQuery.Deferred()
	var geocoder = new google.maps.Geocoder();
	geocoder.geocode(
		{ 'address': address },
		function( results, status ) {
			if ( status == google.maps.GeocoderStatus.OK ) {
				var location = results[0].geometry.location;
				var pos = {
					'location': location,
					'lat': location.lat(),
					'lng': location.lng(),
				}
				def.resolve( pos );
				if( callback && typeof( callback ) === 'function' ) {
					callback( pos );
				}
			}else{
				def.reject()
			}
		}
	);
	return def.promise()
};
