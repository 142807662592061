import React from 'react'
import Market from '../market-card'
import toClassName from '../../functions/to-class-name'
import {SCREENLGMIN} from '/js/variables/breakpoints'

export default class extends React.Component{
	static defaultProps = {
		currentMarket: {},
		noneFoundMessage: 'No events match your search criteria. Try searching a different date or location.'
	}
	getClasses = ()=>{
		var classes = ['markets-list']
		if( this.props.display == 'grid' ){
			classes.push( 'events-grid' )
			classes.push( 'grid' )
		}
		return toClassName( classes )
	}
	getItems = ()=>{
    let screenIsSmall = window.innerWidth <= SCREENLGMIN
		return this.props.markets.map(
			e => {
				var classes = ["grid-item-card", e.status ]
				if( e.id == this.props.currentMarket )
					classes.push('current')
				return (
					<li className={ toClassName( classes ) } key={ e.id } id={"market" + e.id} >
						<Market
							onClick={this.props.marketClicked}
							displayImage={this.props.displayImage}
							market={ e }
							hideVendorList={ screenIsSmall || this.props.hideVendorList }
							noFetch={this.props.noFetch}
						/>
					</li>
				)
			}
		)
	}
	render() {
		return (
			<div>
				<div>
					{ this.props.message &&
						<div className="message message_search">
							{this.props.message}
						</div>
					}
					{ ( this.props.markets.length > 0 ) &&
						<ul className={this.getClasses()}>
							{this.getItems()}
						</ul>
					}
					{ this.props.markets.length == 0 &&
						<div className="message message_search none-found">
							{this.props.noneFoundMessage}
						</div>
					}
				</div>
			</div>
		);
	}
}
