import React from 'react'
import { InfoWindow, Marker } from '../../google-maps-react'
import grey from '/images/mapmarks/grey/grey.png'
import blue from '/images/mapmarks/blue/blue.png'
import yellow from '/images/mapmarks/yellow/yellow.png'

const icons = {
  opennow: blue,
  opentoday: yellow,
  default: grey,
}

const Locations = ({ map, google, markets, setCurrentMarket }) => (
  markets.map(
    ( market, idx ) => {
      let { id, latitude, longitude, status } = market;

      return (
        <Marker
          key={ id }
          position={{ lat: latitude, lng: longitude }}
          map={ map }
          google={ google }
          icon={ icons[status] ? icons[status] : icons['default'] }
          zIndex={ 999 - idx }
          onClick={ ()=>{ setCurrentMarket( market ) } }
        />
      )
    }
  )
)

export default Locations
