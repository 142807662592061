import $ from 'jquery'

$(document).on('gform_post_render', function(){
  // workaround for multiple forms
  var locationSelect = $('.email-subscribe-popup .gfield_checkbox')
  if( locationSelect.length > 1 ){
    locationSelect.each( function(){
      var lis = $( this ).find( 'li' )
      lis.each( function(){
        var li = $(this)
        li.find('label').removeAttr('for').click( function(){
          var name = li.find('input').attr('name')
          var checkbox = $( '.email-subscribe-popup .gfield_checkbox input[name="' + name + '"]')
          checkbox.prop( 'checked', ! checkbox.prop( 'checked' ) )
        } )
      } )
    })
  }

  $('.email-subscribe-input input').click(function(e) {
    setupEmailSubscribePopups();
    $('.email-subscribe-popup').slideDown()
  });
})

function setupEmailSubscribePopups() {
  $( '.field-popout.email-subscribe' ).each( function(){
    var $form = $(this)
    var emailSubscribePopups = $form.find('.email-subscribe-popup').first()
    if (!emailSubscribePopups.hasClass('has-close-option')) {
      var $closePopupEl = $('<div class="close-popup"><i class="fa fa-times"></i></div>');
      emailSubscribePopups.prepend($closePopupEl);
      emailSubscribePopups.addClass('has-close-option');

      var $closePopupEls = $('.close-popup');

      $closePopupEls.click(function() {
        $('.email-subscribe-popup').slideUp()
      });
    }
  })
}
