import React from 'react'
import createReactClass from 'create-react-class'
import $ from 'jquery'
import { css } from 'glamor'
import 'webpack-jquery-ui/datepicker'
import { SCREENXSMAX } from '/js/variables/breakpoints'

let wrap = {
  position: 'relative',
	flexBasis: 145,
	'@media ( max-width: 767px )': {
		flexBasis: '100%',
		margin: '10px 0 0 0',
	},
  '@media (min-width: 768)': {
   margin: '0 10px 0 0',
 },
}
let input = {
  margin: 0,
	width: '100%',
	borderRadius: 3,
  '&.error': {
    borderColor: '#DD5544',
  }
}
let datePicker = {
  position: 'absolute',
  zIndex: '100',
  '@media (max-width: 767px )': {
    left: 0,
    top: 50,
  }
}

export default createReactClass({
	closeDatePicker: function(){
		$(this.refs.datepicker).hide();
	},
	openDatePicker: function(){
		$(this.refs.datepicker).show();
	},
	iWasChanged: function(date){
		$(this.refs.input).removeClass('error');
		if( date )
			this.props.setDate( new Date( date ) )
		else
			this.props.setDate( null )
	},
	handleChange: function(){
		var date = $(this.refs.input).val();
		if (isValidDate(date)) {
			$(this.refs.datepicker).datepicker("setDate", date);
			this.iWasChanged(date);
			this.closeDatePicker();
		} else if (!date) {
			$(this.refs.datepicker).datepicker("setDate", null);
			this.iWasChanged(null);
			this.closeDatePicker();
		} else {
			$(this.refs.input).addClass('error');
		}
	},
	componentDidMount: function(){
		//set up datepicker
		$(this.refs.datepicker).datepicker(
			{
				dateFormat: 'MM d, yy',
				altField: this.refs.input,
				altFormat: 'mm/dd/yy',
				firstDay: 0,
				minDate: 0,
				onSelect: function(date){
					this.closeDatePicker()
					this.iWasChanged(date)
				}.bind(this)
			}
		);
		this.closeDatePicker();

		$('body').on('click', function(event){
			if(
				$.contains( this.refs.wrap, event.target ) ||
				$(event.target).is('.ui-datepicker-header span.ui-icon') // for some reason, jQuery doesn't detect the prev and next buttons as descendents of wrap
			) {} else{
				this.closeDatePicker()
			}
		}.bind(this))
	},
	componentDidUpdate: function(){
		$(this.refs.datepicker).datepicker("setDate", this.props.date);
	},
	render: function(){
		return (
			<div className={ css( wrap ) } ref="wrap">
				<input
					ref="input"
					onFocus={this.openDatePicker}
					className={"date " + css( input )}
					placeholder="Search by Date"
					onKeyUp={this.handleChange}
				/>
				<div className={ `datepicker ${ css( datePicker ) }`} ref="datepicker" />
			</div>
		)
	}
})



// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
function isValidDate(dateString) {
	// First check for the pattern
	if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
		return false;

	// Parse the date parts to integers
	var parts = dateString.split("/");
	var day = parseInt(parts[1], 10);
	var month = parseInt(parts[0], 10);
	var year = parseInt(parts[2], 10);

	// Check the ranges of month and year
	if(year < 1000 || year > 3000 || month == 0 || month > 12)
		return false;

	var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

	// Adjust for leap years
	if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
		monthLength[1] = 29;

	// Check the range of the day
	return day > 0 && day <= monthLength[month - 1];
};
