import PermalinkBase from '../../variables/siteurl'
import $ from 'jquery'
import OTG_API from './otg-api-get'
import OTGDate from './otg-date'
import {DateTime} from 'luxon'

const allowedLocationTypes = ['Public Market', 'POD', 'Relief Effort', 'Creator Food Spot']
const today_ts = DateTime.fromObject({}, {zone:'America/Los_Angeles'}).ts;

export default class Location {
  static cache = {}
  static isAllowed = locationData => {
      return -1 !== allowedLocationTypes.indexOf( locationData.locationType )
  }
  get mapIcon(){
    var colors = {
      'opentoday': 'yellow',
      'opennow': 'blue'
    }
    var color = colors[this.status] ? colors[this.status] : 'grey'
    var icon = THEME_DIR_URI + '/images/mapmarks/' + color + '/' + color + '.png'
    if( this.locationType == 'Relief Effort' ){
      icon = THEME_DIR_URI + '/images/disaster-relief-icon.png'
    }
    return icon
  }
  constructor( data ){
    if( Location.cache[ data.id ] ){
      $.extend( this, Location.cache[ data.id ], data )
      this.setupAtts()
    } else {
      $.extend( this, data )
      this.setupAtts()
    }
  }
  fetchDetails(){
    var def = $.Deferred()
    if( this.detailsFetched ){
      def.resolve( this )
    } else {
      OTG_API.get( '/locations/' + this.id )
        .done( function( response ){
          $.extend( this, response.data.location )
          this.detailsFetched = true
          this.setupAtts()
          def.resolve( this )
        }.bind(this) )
        .fail( def.reject )
    }
    return def.promise()
  }
  fetchEvents( args ){
    if( this.eventsDef === undefined ){
      this.eventsDef = $.Deferred()
    }
    let def = this.eventsDef
    if( this.events === undefined ){
      var data = $.extend( args, { locationId: this.id } )
      this.events = 'loading'
      OTG_API.getEvents( data )
        .done( e => {
          this.events = e
          this.setupAtts()
          def.resolve(e)
        } )
        .fail(
          response => {
            this.events = undefined
            console.warn(
              'Location.fetchEvents() request failed',
              this,
              response
            )
            def.reject( response )
          }
        )
    } else {
      def.resolve( this.events )
    }
    return def.promise()
  }
  setupAtts(){
    this.permalink = PermalinkBase + '/event/' + this.slug
    this.address = this.address1
    this.status = 'closed'
    this.latitude = parseFloat( this.latitude )
    this.longitude = parseFloat( this.longitude )
    if( Array.isArray( this.events ) ){
      
      var now = DateTime.fromMillis(today_ts);

      this.events.some( function( e ){
        var start = e.startTime
        var end = e.endTime
        if( start.toDateString() == now.toLocaleString( 'en-US' ) && end.valueOf() > now.valueOf() ){
          this.status = ( start.valueOf() < now.valueOf() && end.valueOf() > now.valueOf()  ) ? 'opennow' : 'opentoday'
        }
        return this.status == 'opennow'
      }.bind(this))
    }
    Location.cache[this.id] = this
  }
}
