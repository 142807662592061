import React from 'react'
import { Map, Popup } from '../../google-maps-react'
import { css } from 'glamor'
import Locations from './locations'
import Market from '../../market-card'
import close from '/images/mobile-close.svg'

const closeButtonStyles = {
  position: 'relative',
  zIndex: '10',
  float: 'right',
  background: 'rgba( 255, 255, 255, .5 )',
  padding: 5,
  height: 26,
  ':hover': {
    cursor: 'pointer',
  }
}

const MarketsMapView = ({ currentMarket, location, markets, onBoundsChanged, onReady, setCurrentMarket }) => (
  <Map google={ window.google }
    zoom={ 13 }
    center={ location }
    // Map component from FullStackReact doesn't support this option
    zoomControlOptions={{position: google.maps.ControlPosition.LEFT_TOP}}
    onIdle={ onBoundsChanged }
    onReady={ onReady }
    onClick={ ()=>{ setCurrentMarket( null ) } }
  >
    { currentMarket &&
      <Popup
        position={ { lat: currentMarket.latitude, lng: currentMarket.longitude } }
      >
      { currentMarket &&
        <div className={ css({ position: 'absolute', left: '-15em', width: '30em', bottom: '5em' }) } >
          <img className={ css( closeButtonStyles )} src={close} alt="close" onClick={ ()=>{ setCurrentMarket(null) } } />
          <Market market={ currentMarket } />
        </div>
      }
      </Popup>
    }
    <Locations markets={ markets } setCurrentMarket={ setCurrentMarket } />
  </Map>
)

export default MarketsMapView
