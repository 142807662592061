import $ from 'jquery'
import OTG_API from './otg-api-get'

	var cuisineCache = false
	var defs = []
	var timeout = false
	OTG_API.getCuisine = function( id ){
		var def = $.Deferred()
		if( cuisineCache ){
			def.resolve( cuisineCache[id] )
		} else {
			if( timeout ){ clearTimeout( timeout ) }

			defs.push( { id: id, def: def } )
			timeout = setTimeout( function(){
				cuisineCache = {}
				OTG_API.get( '/cuisines' )
					.done( function( response ){
						response.data.cuisines.forEach( function( c ){
							cuisineCache[ c.id ] = c
						})
						defs.forEach( function( d ){
							d.def.resolve( cuisineCache[d.id] )
						})
					})
					.fail( function(){
						defs.forEach( function( d ){ d.def.reject( ) } )
					})
			}, 150 )

		}

		return def
	}


  export default OTG_API
