// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/images/truck-grey.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vendor-count::before{content:" ";margin-right:3px;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;display:inline-block;height:1.3em;width:2em;vertical-align:sub}`, "",{"version":3,"sources":["webpack://./js/components/vendor-count.scss"],"names":[],"mappings":"AACE,sBACE,WAAA,CACA,gBAAA,CACA,wDAAA,CACA,2BAAA,CACA,oBAAA,CACA,YAAA,CACA,SAAA,CACA,kBAAA","sourcesContent":[".vendor-count {\n  &::before {\n    content: ' ';\n    margin-right: 3px;\n    background-image: url('/images/truck-grey.svg');\n    background-repeat: no-repeat;\n    display: inline-block;\n    height: 1.3em;\n    width: 2em;\n    vertical-align: sub;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
