import React from 'react'
import VendorCount from './vendor-count'
import './upcoming.scss'

export default class extends React.Component {
  static defaultProps = { events: [] }
  render(){
    return Array.isArray( this.props.events ) && this.props.events.length > 0 ? (
      <ul className="upcoming event-dates event-dates-">
        <li className="upcoming__label">Upcoming Schedule:</li>
        {this.props.events.map( (e)=>{
          return <UpcomingEvent event={e} key={e.id} hideVendorList={this.props.hideVendorList} />
        } )}
      </ul>
    ) : false
  }
}

class UpcomingEvent extends React.Component {
  state = {
    maxVendors: 10
  }
  theVendors(){
    var event = this.props.event
    if( event.services ){
      var services = this.state.maxVendors ? event.services.slice( 0, 10 ) : event.services
      var viewMore = this.state.maxVendors && event.services.length > this.state.maxVendors
      /* This part depends on OTG_API.getVendors() being called before for the proper vendor details */
      var vendors = services.map( function( service, i ){
        var vendor = service.vendor
        return <a key={ i } href={ vendor.permalink }><span>{vendor.name}</span></a>
      } )

      if( viewMore ){
        vendors.push(
          <a
            className="vendors-more"
            key='more'
            onClick={ function(){ this.setState( {maxVendors: false} ) }.bind(this) }
            ><span>more&hellip;</span>
          </a>
        )
      }
      return vendors
    }
  }
  componentDidMount = ()=>{
      this.props.event.fetchDetails().always(
        ()=>{
          this.setState( {df: 'fetched'} )
        }
      )
  }
  render(){
    var e = this.props.event
    return (
      <li key={e.id}>
        <span className="upcoming__day">{ e.startTime.getWeekDay() }</span>
        <span className="upcoming__date">{ e.startTime.getMonth() }/{ e.startTime.getDate() }</span>
        <span className="upcoming__time">{ e.timeSpan }</span>
        { e.services && <VendorCount count={e.services.length} /> }
        { e.services && ! this.props.hideVendorList &&
          <span className="see-vendors-list">See Creators
            <div className="vendors-list">
              <h6>Creators List</h6>
              {this.theVendors( e )}
            </div>
          </span>
        }
      </li>
    )
  }
}
