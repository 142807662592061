import Location from './location'
export default class extends Array {
  /* Caution: The native Array methods usually return a new Array; this can have confusing results.
   * Also, get propName() doesn't work here.
  */
  getLocations = () => {
    // using Object.keys to get an array of unique IDs
    var locations = {}
    this.forEach( event => { locations[ event.locationId ] = '' } )
    locations = Object.keys(locations)
    locations = locations.map(
      l => {
        return new Location( {id:l} )
      }
    )
    return locations
  }
  byDate = () =>{
    var byDate = {}
    this.forEach( event => {
      if( byDate[ event.date ] == undefined ) byDate[ event.date ] = []
      byDate[ event.date ].push( event )
    })
    return byDate
  }
}
