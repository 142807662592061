import $ from 'jquery'
import React from 'react'
import View from './view'

import getImageSize from '../../functions/get-image-size'

export default class MarketCard extends React.Component {
  static defaultProps = { displayImage: true, displayStatus: true }
  state = {}
  clicked = (event) => {
    if( this.props.onClick && ! $(event.target).is('.vendors-more') ){
      this.props.onClick( event, this.props.market )
    }
  }
  getCardImage = () => {
    if( this.props.displayImage ){
      var m = this.props.market
      if( m.imageUrl ){
        var src = getImageSize( '--small', m.imageUrl )
      } else {
        var src = THEME_DIR_URI + "/images/placeholder-truck.svg"
      }
      return <div className="card__image card__image--50" style={{ backgroundImage: 'url(' + src + ')' }}/>
    }
    return false
  }
  componentDidMount(){
    if( this.props.noFetch !== true ){
      this.props.market.fetchEvents().done(
        events =>{ this.setState( { events: events } ) }
      )
    }
  }
  componentWillReceiveProps( newProps ){
    if( newProps.noFetch !== true && this.props.market != newProps.market ){
      newProps.market.fetchEvents().done(
        events =>{ this.setState( { events: events } ) }
      )
    }
  }
  render(){
    var m = this.props.market
    if( ! this.props.displayStatus ){ m.status = '' }
    var Header = <header className=".market-card__highlight">
      <span className="location">{m.name}</span>
      { m.status && m.status != 'closed' &&
        <span className={`tag tag--${m.status}`}>{this.formatStatus(m.status)}</span>
      }
    </header>
    return (
      <View
        clicked={ this.clicked }
        m={ m }
        Header={ Header }
        style={ this.props.style }
        onClick={ this.props.onClick }
        getCardImage={ this.getCardImage }
        hideVendorList={ this.props.hideVendorList}
        events={ this.state.events }
      />
    )
  }
  formatStatus( status ) {
    switch( status ) {
      case 'opentoday':
        status = 'open today';
        break;
      case 'opennow':
        status = 'open now';
        break;
    }
    return status;
  }
}
