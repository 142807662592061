import toClassName from '../functions/to-class-name'
import MarkerWithLabel from './marker-with-label'
import $ from 'jquery'

if( window.isSecureContext === undefined ) window.isSecureContext = (window.location.protocol == 'https:' || window.location.hostname == 'localhost')

function OTGMap( element, options ) {
	this.element = element;
	this.$element = $( this.element );
	this._defaults = $.fn.OTGMap.defaults;
	this.options = $.extend( {}, this._defaults, options );

	this.init();
}

OTGMap.prototype = {
	init: function () {
		this.buildMap();
		this.markerZIndex = 1;
	},

	buildMap: function() {
		var defaultMarkers = this.$element.find('.marker');
		var args = {
			center: this.options.center,
			zoom: this.options.zoom,
			scrollwheel: this.options.scrollwheel,
			zoomControlOptions: this.options.zoomControlOptions
		};

		this.map = new google.maps.Map( this.element, args );
		this.map.markers = [];

		var otgmap = this;
		defaultMarkers.each(function() {
			var args = {
				'lat': parseFloat( $(this).attr('data-lat') ),
				'lng': parseFloat( $(this).attr('data-lng') ),
			}
			if( $(this).html().trim() ) {
				args.content = $(this).html();
			}
			otgmap.addLocation( args );
		});

		this.map.addListener('dragend', function() {
			if( typeof otgmap.options.centerChanged === 'function' ) {
				otgmap.options.centerChanged( otgmap.map.getCenter() );
			}
		});
	},

	centerMap: function( position ) {
		if( typeof position !== 'undefined' ) {
			this.map.setCenter( position );
		} else {
			var bounds = new google.maps.LatLngBounds();

			// loop through all markers and create bounds
			this.map.markers.forEach( function( marker ) {
				var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
				bounds.extend( latlng );
			});

			this.map.fitBounds( bounds );
		}
	},

	addLocations: function( locations ) {
		for( var i = 0; i < locations.length; i++ ) {
				this.addLocation( locations[ i ] );
		}
	},

	addLocation: function( args ) {
		var otgmap = this;
		var marker = new MarkerWithLabel({
			position: { 'lat': args.lat, 'lng': args.lng },
			map: this.map,
			icon: args.icon ? args.icon : THEME_DIR_URI + '/images/mapmarks/grey/default.png',
			labelContent: typeof args.label !== 'undefined' ? args.label : '',
			labelAnchor: new google.maps.Point(0, 40),
			labelClass: 'marker-label',
			labelInBackground: false,
			zIndex: args.zIndexOffset ? this.markerZIndex + args.zIndexOffset : this.markerZIndex,
		});

		if( typeof args.content !== 'undefined' ) {
			var infowindow = new google.maps.InfoWindow({
				content: args.content,
			});
			google.maps.event.addListener( marker, 'click', function() {
				marker.setZIndex( ++otgmap.markerZIndex );
				this.openInfowindow( args.id );
			}.bind( this ));
			google.maps.event.addListener(infowindow, 'domready', $.fn.OTGMap.styleInfowindow);

			marker.infowindow = infowindow;
		}

		if( typeof args.id !== 'undefined' ) {
			this.map.markers[ args.id ] = marker;
		} else {
			this.map.markers.push( marker );
		}
	},
	addMarketMarker: function( market ){
		var marketContent = $('<div class="map-marker grid-item-card">').addClass(toClassName(market.status))[0]
		var zIndexOffset = 0
		if( market.status == 'opennow' )
			zIndexOffset = 200
		else if( market.status == 'opentoday' )
			zIndexOffset = 100
		this.addLocation(
			{
				'lat': market.latitude,
				'lng': market.longitude,
				'content': marketContent,
				'id': market.id,
				'icon': market.mapIcon,
        'visible': true,
				'zIndexOffset': zIndexOffset
			}
		)
	},
	getLocationMarker: function( id ) {
		return this.map.markers[ id ];
	},
	getMap: function(){
		return this.map
	},
	getMarkers: function(){
		return this.map.markers
	},
	closeInfoWindow: function(){
		if( this.openWindow ) this.openWindow.close()
	},
	openInfowindow: function( id ){
		var otgmap = this
		if( typeof otgmap.openWindow !== 'undefined' ) {
			otgmap.openWindow.close();
		}

		var marker = this.getLocationMarker( id )
    if( marker ){
      var infowindow = marker.infowindow
  		infowindow.open( this.map, marker );
  		otgmap.openWindow = infowindow;
    } else {
     console.log('map marker not found', id)
    }
	}
}

$.fn.OTGMap = function( opt ) {
	var args = Array.prototype.slice.call(arguments, 1);
	var returnValue = this
	this.each(function() {
		var item = $(this);
		var instance = item.data('OTGMap');

		if( ! instance ) {
			item.data('OTGMap', new OTGMap(this, opt));
		} else {
			if( typeof opt === 'string' ) {
				returnValue = instance[opt].apply(instance, args);
			}
		}
	});
	return returnValue
}

$.fn.OTGMap.defaults = {
	center:        { lat: 37.7749, lng: -122.4194 }, // san francisco
	centerChanged: null,
	zoom:          11,
	scrollwheel:   false,
};
