import $ from 'jquery'
import React from 'react'
export default class extends React.Component{
	moveAutocompleteSuggestions(){
		$('.pac-container').appendTo(this.refs['wrap'])
	}
	onChange( event ){
		this.props.updateAddress( event )
		this.moveAutocompleteSuggestions()
	}
	componentDidMount(){
		var chooser = this;
		var element = this.refs.input;

		var autocomplete = new google.maps.places.Autocomplete( element );
		autocomplete.addListener('place_changed', function() {
			var event = $.Event( 'change', { target: element } );
			chooser.props.updateAddress( event );
		});
	}
	render(){
		return (
			<input
				ref="input"
				className="location"
				placeholder="Address, City or Zipcode"
				onChange={(event)=>{ this.onChange(event) }}
				value={this.props.value}
				onKeyUp={
					function(e){
						if( e.keyCode == 13 )
							this.props.updateMarkets()
					}.bind(this)
				}
			/>
		)
	}
}
