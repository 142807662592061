import siteURL from '../../variables/siteurl'
let OTG_API = {}
const PermalinkBase = siteURL

	if (!Array.prototype.find) {
	  Array.prototype.find = function(predicate) {
	    if (this === null) {
	      throw new TypeError('Array.prototype.find called on null or undefined');
	    }
	    if (typeof predicate !== 'function') {
	      throw new TypeError('predicate must be a function');
	    }
	    var list = Object(this);
	    var length = list.length >>> 0;
	    var thisArg = arguments[1];
	    var value;

	    for (var i = 0; i < length; i++) {
	      value = list[i];
	      if (predicate.call(thisArg, value, i, list)) {
	        return value;
	      }
	    }
	    return undefined;
	  };
	}

	// Some common IE shims... indexOf, startsWith, trim

	/*
	  Really? IE8 Doesn't have .indexOf
	*/
	if (!Array.prototype.indexOf) {
	  Array.prototype.indexOf = function (searchElement /*, fromIndex */ ) {
	    "use strict";
	    if (this === null) {
	      throw new TypeError();
	    }
	    var t = Object(this);
	    var len = t.length >>> 0;

	    if (len === 0) {
	      return -1;
	    }
	    var n = 0;
	    if (arguments.length > 1) {
	      n = Number(arguments[1]);
	      if (n != n) { // shortcut for verifying if it's NaN
	        n = 0;
	      } else if (n !== 0 && n != Infinity && n != -Infinity) {
	        n = (n > 0 || -1) * Math.floor(Math.abs(n));
	      }
	    }
	    if (n >= len) {
	      return -1;
	    }
	    var k = n >= 0 ? n : Math.max(len - Math.abs(n), 0);
	    for (; k < len; k++) {
	      if (k in t && t[k] === searchElement) {
	        return k;
	      }
	    }
	    return -1;
	  };
	}

	/*
	  IE Doesn't have a .startsWith either?
	*/
	if (!String.prototype.startsWith) {
	  String.prototype.startsWith = function (str){
	    return this.lastIndexOf(str, 0) === 0;
	  };
	}

	// IE < 9 doesn't have a trim() for strings
	if (!String.prototype.trim) {
	  String.prototype.trim = function () {
	    return this.replace(/^\s+|\s+$/g, '');
	  };
	}

	var _marketObjectCache = {}
	function Market( data ){
		var methods = {
			createNewMarket: function(){
				var def = $.Deferred()
				this.promise = def.promise()
				_marketObjectCache[this.id] = this
				def.resolve( this )
			}.bind(this),
			fetchMarketDetails:function(){
				if( ! this.detailsFetched ){
					var url = API_PASSTHROUGH + '/markets/' + this.id + '.json/'
					return $.get(url )
						.done( function(){ this.detailsFetched = true }.bind(this) )
						.done( function( data ){
							$.extend(
								this,
								data.MarketDetail.Market,
								data.MarketDetail.Market.Market
							)
							this.media = this.media ? this.media : this.Media
							this.events = this.events ? this.events : this.Event
							this.longitude = parseFloat( this.longitude )
							this.latitude = parseFloat( this.latitude )
							methods.setupAmenitiesProperty( data.MarketDetail.Amenities )
							methods.setupEventsProperty( data.MarketDetail.Events )
							methods.setupVendorsProperty( data.MarketDetail.Events )
							_marketObjectCache[this.id] = this
						}.bind(this) )
				} else {
					return $.when() // returns resolved promise
				}
			}.bind(this),
			getFromCache: function(){
				$.extend( this, _marketObjectCache[this.id] )
			}.bind(this),
			setupAmenitiesProperty: function( amenities ){
				this.amenities = []
				amenities.forEach( function( a ){
					a = a.Amenity
					this.amenities[parseInt(a.amenity_type_id)] = a.amenity_type
				}.bind(this))
			}.bind(this),
			setupEventsProperty: function( events ){
				this.events = this.events.map( function( event, index ){
					if( Array.isArray( events[index].Vendors ) ){
						var vendors = events[index].Vendors.map( function( vendor ){
							return OTG_API.getVendor( vendor )
						})
						event.vendors = vendors
					}
					return event
				})
			}.bind(this),
			setupVendorsProperty: function( events ){
				this.vendors = []
				events.forEach( function( event ){
					event.Vendors.forEach( function( vendor ){
						this.vendors[vendor.id] = OTG_API.getVendor(vendor)
					}.bind(this) )
				}.bind(this) )
			}.bind(this)
		}
		$.extend( this, data )
		if( _marketObjectCache[ this.id ] !== undefined ){
			methods.getFromCache()
			if( data.distance ) this.distance = data.distance
			if( data.Market && data.Market.distance ) this.distance = data.Market.distance
		}else{
			methods.createNewMarket()
		}
		this.fetchDetails = methods.fetchMarketDetails
		this.longitude = parseFloat( this.longitude )
		this.latitude = parseFloat( this.latitude )
	}
	Market.prototype.getMedia = function( size_name ){
		if( this.media && this.media.length > 0 ){
			var item = this.media.find( function( media ){
				return media.size_name == size_name
			} )
			return item ? item.media_url : null
		}
	}
	Object.defineProperty( Market.prototype, 'image', {
		get: function(){
			// this should be refactored to use above getMedia method
			if( this.media && this.media.length > 0 ){
				var hero = this.media.find( function( media ){
					return media.size_name == "hero_small"
				})
				return hero ? hero.media_url : null
			}
		}
	} )
	Object.defineProperty( Market.prototype, 'hero', {
		get: function(){
			return this.getMedia( 'hero' )
		}
	})
	Object.defineProperty( Market.prototype, 'permalink', {
		get: function(){
			return PermalinkBase + '/event/' + this.id + '/' + toClassName( this.name )
		}
	})
	Object.defineProperty( Market.prototype, 'mapIcon', {
		get: function(){
			var market = this
			var colors = { 'opennow': 'blue', 'opentoday': 'yellow', 'default': 'grey' }
			var color = colors[market.status] ? colors[market.status] : colors['default']
			var number = market.events[0] && market.events[0].vendors ? '__' + market.events[0].vendors.length : ''

			return THEME_DIR_URI + '/images/mapmarks/' + color + '/' + color + number + '.png'
		}
	})

	OTG_API.markets = [];
	OTG_API.getCities = function(){
		var def = $.Deferred()
		var endpointURL = 'https://www.offthegridmarkets.com/api/v1.0/home/cities.json'
		var r = $.get( endpointURL )
		var cities = []
		r.done( function( data ){
			data.HomeCities.forEach( function( o ){
				cities.push( o.City )
			} )
			def.resolve( cities )
		} )
		return def.promise()
	}
	OTG_API.getMarket = function( market ){
		return new Market( market )
	}
	OTG_API.getMarkets = function( args ){
		var methods = {
			sendNotifications: function( def, markets, promises ){
				def.notify( { status: 'marketsLoaded', markets: markets } )
				$.when.apply( null, promises )
					.done( function(){
						def.resolve( { status: 'marketsComplete', markets: markets } )
					})
					.fail( function(){
						def.reject( { status: 'fail', markets: markets })
					} )

			},
			sendRequest: function( location ){
				var endpointURL = API_PASSTHROUGH + '/markets.json';
				var data = {
					'latitude'  : location.lat,
					'longitude' : location.lng,
					'sort-order': 'distance-asc'
				};
				return $.get({
					url: endpointURL,
					data: data
				} );
			}.bind(this),
			setupPromises: function( markets ){
				var promises = []
				markets.forEach( function( m, index ){
					promises.push( m.promise )
					m.promise.done( function(){
						def.notify( { status: 'marketComplete', index: index, markets: markets })
					})
				} )
				return promises
			},
			validateCall: function( location ){
				if( this !== OTG_API )
					throw "getMarkets must be called on OTG_API object"
				if(
					! $.isNumeric(location.lng) && ! $.isNumeric(location.lat) &&
					! Array.isArray( location )
				){
					throw "location parameter must have numeric lat and lng properties" +
					" or be an array of market IDs."
				}
				if( $.isNumeric(location.lng) ){
					return 'location'
				} else {
					return 'ids'
				}
			}.bind(this),
			finalizeCall: function (response){
				var markets = response.Markets.map( function( data, index ){
					return new Market( $.extend( data.Market, { events: data.Event, media: data.Media } ) )
				})
				var promises = methods.setupPromises( markets )
				methods.sendNotifications( def, markets, promises )
			},
			tryRequest: function(args, retriesLeft) {
				if (retriesLeft < 1) {
					console.log("Loading markets failed, retries exhausted.");
					return;
				}

				var r = methods.sendRequest(args);

				r.done(function( response ){
					//TODO: sometimes this response is null, look into why and devise a better permanent solution.
					if (!response || !response.hasOwnProperty("Markets")) {
						console.log("There was an error with the request for markets, retrying...");
						//try again
						setTimeout(methods.tryRequest(args, retriesLeft - 1), 500);
					} else {
						methods.finalizeCall(response);
					}
				}.bind(this));

				r.fail(function(jqXHR, textStatus, errorThrown) {
				  console.log("jqXHR: ", jqXHR);
				  console.log("textStatus: ", textStatus);
				  console.log("errorThrown: ", errorThrown);
				  setTimeout(methods.tryRequest(args, retriesLeft - 1), 500);
				});
			}
		}
		var def = $.Deferred()
		var callType = methods.validateCall( args )
		switch ( callType ) {
			case 'location':
				methods.tryRequest(args, 5);
				break;
			case 'ids':
				var markets = args.map( function( id ){
					return new Market( {id: id} )
				} )
				var promises = markets.map( function( market ){
					return market.fetchDetails()
				} )
				$.when.apply( null, promises ).done( function(){
					def.resolve( { markets: markets } )
				})
				break;
		}
		return def.promise()
	}
export default OTG_API
