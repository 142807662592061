import React from 'react'

export default class extends React.Component {
	render(){
		return(
			<input
				ref="input"
				className="markets-search"
				placeholder="Search Markets"
				onChange={(event)=>{ this.props.updateSearch( event.target.value ) }}
				value={this.props.value}
				onKeyUp={
					function(e){
						if( e.keyCode == 13 )
							this.props.updateSearch()
					}.bind(this)
				}
			/>
		)
	}
}
